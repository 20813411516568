<template>
  <form @submit.prevent="send">
    <section class="section py-5">
      <div class="container">
        <div class="columns">
          <div class="column has-text-centered primary-text">
            <h2 class="is-size-3 has-text-weight-bold heading-center mb-4">
              SANKOライフガード（賃貸住宅総合保険）<br />
              お申込手続き
            </h2>
          </div>
        </div>
      </div>
    </section>
    <!-- 重要事項説明 -->
    <section class="section py-5 important-section">
      <div class="container">
        <div class="columns">
          <div class="column has-text-centered primary-text">
            <h2 class="is-size-4 has-text-weight-bold heading-center mb-4"><span class="material-icons accent-color">report</span>重要事項説明</h2>
            <div class="important-info">
              <p class="mb-5">
                ご契約前に、重要事項（「契約概要」「注意喚起情報」）を必ずお読みになり内容に同意してください<br />
                重要事項説明書をダウンロードしないと「同意する」にチェックできません
              </p>
              <div class="columns is-centered">
                <div class="column is-6 has-text-centered primary-text">
                  <div class="field">
                    <div class="control">
                      <a
                        class="button is-medium is-fullwidth accent-color-bg w-space-normal h-auto"
                        @click="importantRead = true"
                        href="/pdf/important.pdf"
                        target="_blank"
                        rel="noopener"
                      >
                        重要事項説明書をダウンロード
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <input type="checkbox" v-model="form.tf_msjyjk" :disabled="!importantRead" id="important-agree" class="check-type-1" />
              <label for="important-agree" class="primary-text">重要事項説明書に同意する</label>
              <ErrorField :error="f$.tf_msjyjk.$errors" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 保険契約上の意向確認 -->
    <section class="section py-5">
      <div class="container">
        <div class="columns">
          <div class="column has-text-centered primary-text">
            <h2 class="is-size-4 has-text-weight-bold heading-center mb-4">
              <span class="material-icons accent-color">announcement</span>保険契約上の意向確認
            </h2>
            <table class="table border-divider-color is-bordered mx-auto">
              <tbody>
                <tr>
                  <td class="has-text-left">この保険は「家財」と「賠償責任」を保険の対象としています。ご確認頂けましたか？</td>
                  <td class="w-space-nowrap">
                    <input type="checkbox" v-model="form.target_verify" id="target-verify-yes" name="target-verify" class="check-type-1" />
                    <label for="target-verify-yes" class="primary-text">はい</label>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">地震保険が付帯されていないことをご確認いただきましたか？</td>
                  <td class="w-space-nowrap">
                    <input
                      type="checkbox"
                      v-model="form.earthquake_verify"
                      id="earthquake-verify-yes"
                      name="earthquake-verify"
                      class="check-type-1"
                    />
                    <label for="earthquake-verify-yes" class="primary-text">はい</label>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">「保険金をお支払いできない場合」や「ご契約後の通知義務」についてご確認いただけましたか？</td>
                  <td class="w-space-nowrap">
                    <input
                      type="checkbox"
                      v-model="form.notification_verify"
                      id="notification-verify-yes"
                      name="notification-verify"
                      class="check-type-1"
                    />
                    <label for="notification-verify-yes" class="primary-text">はい</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <ErrorField :error="intentionError" />
          </div>
        </div>
      </div>
    </section>

    <!-- 当社保険加入状況の確認 -->
    <section class="section py-5">
      <div class="container">
        <div class="columns">
          <div class="column has-text-centered primary-text">
            <h2 class="is-size-4 has-text-weight-bold heading-center mb-4">
              <span class="material-icons accent-color">priority_high</span>当社保険加入状況の確認
            </h2>
            <p class="mb-5">ご入居される方の中に「SANKOライフガード」または「SANKOライフ保険」に現在ご加入中の方はおられますか？</p>
            <div class="columns is-justify-content-center">
              <div class="field">
                <div class="control">
                  <input
                    type="radio"
                    v-model="form.another_contract"
                    :value="ari"
                    id="own-other-contract-yes"
                    class="radio-type-2"
                    @focus="f$.another_contract.$reset()"
                    @change="f$.another_contract.$touch()"
                  />
                  <label for="own-other-contract-yes" class="mr-6">はい</label>
                  <input
                    type="radio"
                    v-model="form.another_contract"
                    :value="''"
                    id="own-other-contract-no"
                    class="radio-type-2"
                    @focus="f$.another_contract.$reset()"
                    @change="f$.another_contract.$touch()"
                  />
                  <label for="own-other-contract-no">いいえ</label>
                </div>
              </div>
            </div>
            <ErrorField :error="f$.another_contract.$errors" />
          </div>
        </div>
      </div>
    </section>

    <!-- メールアドレス登録 -->
    <section class="section py-5 mail-section mb-5">
      <div class="container">
        <div class="columns">
          <div class="column has-text-centered primary-text">
            <h2 class="is-size-4 has-text-weight-bold heading-center mb-4">
              <span class="material-icons accent-color">email</span>メールアドレス登録
            </h2>
            <div class="important-info">
              <p class="mb-4">
                お申込み用のメールアドレスを登録してください<br />
                申込開始メールをお送りします
              </p>
              <div class="columns is-centered">
                <div class="column is-8">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input
                        class="input"
                        type="text"
                        v-model="form.tf_mskyma"
                        @focus="f$.tf_mskyma.$reset(), f$.$clearExternalResults()"
                        @blur="f$.tf_mskyma.$touch()"
                      />
                      <span class="icon is-small is-left">
                        <span class="material-icons">email</span>
                      </span>
                    </div>
                  </div>
                  <ErrorField :error="f$.tf_mskyma.$errors" />
                  <p class="text-info">※契約者様のメールアドレスで、この場ですぐに確認できるアドレスをご指定ください</p>
                </div>
              </div>
              <p class="mb-4">
                入力されたメールアドレスに確認番号を送付致します<br />
                確認番号が届きましたら、次画面で確認番号を入力してください
              </p>
              <div class="field mb-5">
                <div class="control">
                  <a class="button is-medium accent-color-bg px-6" @click="send">送信</a>
                </div>
              </div>
              <p><a @click="noMail">メールアドレスのない方はこちら</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, UnwrapRef } from "vue";
import { useStore } from "@/store";
import { SCR010Form, SCR010FormValidateRule } from "@/forms/scr010";
import useVuelidate from "@vuelidate/core";
import constants from "common/src/constants";
import ErrorField from "common/src/components/ErrorField.vue";
import { EmailRequest, postEmailRequest } from "@/api/email";
import axios from "axios";
import { setErrorMessage } from "common/src/utils/validate";
import { ErrorResponse } from "common/src/utils/request";
import { scrollToError } from "common/src/utils/screen";
import { StepMutationTypes } from "@/store/modules/steps";
import router from "@/router";
import { EntryMutationTypes } from "@/store/modules/entry";
import { MailMutationTypes } from "@/store/modules/mail";
import { AnotherMutationTypes } from "@/store/modules/another";

export default defineComponent({
  components: {
    ErrorField,
  },
  setup() {
    const importantRead = ref(false);
    // フォーム変数作成
    const form: UnwrapRef<SCR010Form> = reactive({
      tf_msjyjk: false,
      target_verify: false,
      earthquake_verify: false,
      notification_verify: false,
      another_contract: null,
      tf_mskyma: null,
    });

    // ストア使用時はuseStoreを呼び出す
    const store = useStore();

    // 一度、当画面の処理が完了したら初期表示時にチェックを行う
    if (store.state.steps.step1) {
      form.tf_msjyjk = true;
      form.target_verify = true;
      form.earthquake_verify = true;
      form.notification_verify = true;
      importantRead.value = true;
    }
    // ストアから他保険を復元
    if (store.state.another.hasAnother != null) {
      form.another_contract = store.state.another.hasAnother;
    }
    // ストアからメールアドレスを復元
    if (store.state.mail.mailAddress) {
      form.tf_mskyma = store.state.mail.mailAddress;
    }
    // 当社保険加入状況あり時の定数
    const ari = constants.ARI.ARI;

    // 全体エラーメッセージ
    const g$: string[] = reactive([]);

    // フィールドエラーを外部からセットする変数
    const $externalResults = ref({});
    // フィールドエラー設定初期化
    const f$ = useVuelidate(SCR010FormValidateRule, form, {
      $externalResults,
    });

    // 意向確認のエラーを１つだけ表示
    const intentionError = computed(() => {
      if (f$.value.target_verify.$error || f$.value.earthquake_verify.$error || f$.value.notification_verify.$error) {
        return [...f$.value.target_verify.$errors, ...f$.value.earthquake_verify.$errors, ...f$.value.notification_verify.$errors][0].$message;
      }
      return "";
    });

    // 本人確認リクエストのメールを送付します
    const send = async () => {
      f$.value.$clearExternalResults();

      // メールアドレスが必須なのは本人確認リクエストを行う場合のみなので手動チェック
      if (form.tf_mskyma === "" || form.tf_mskyma === null) {
        $externalResults.value = { tf_mskyma: "メールアドレスを入力してください" };
      }
      f$.value.$touch();
      if (f$.value.$invalid) {
        scrollToError();
        return;
      }

      try {
        // API0210呼び出し
        const req: EmailRequest = {
          mailAddress: form.tf_mskyma ?? "",
        };
        const res = await postEmailRequest(req);

        // ストアへシリアル番号をCommit
        store.commit(EntryMutationTypes.SET_SERIAL, res.serialNo);
        store.commit(MailMutationTypes.SET_MAIL, req.mailAddress);
        store.commit(StepMutationTypes.STEP1, true);
        store.commit(AnotherMutationTypes.SET_ANOTHER, form.another_contract);

        // SCR020メールアドレス確認画面へ遷移
        router.push({ name: "ConfirmEmailAddress" });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 400) {
          setErrorMessage(g$, $externalResults, err.response?.data as ErrorResponse);
        }
      }
    };

    // メールアドレスがない方をクリック時
    const noMail = () => {
      f$.value.$clearExternalResults();
      f$.value.$touch();
      if (f$.value.$invalid) return;

      store.commit(StepMutationTypes.STEP1, true);
      store.commit(StepMutationTypes.STEP2, true);

      // SCR030管理番号入力画面へ移動
      router.push({ name: "ControlNumberInput" });
    };

    return {
      importantRead,
      form,
      f$,
      g$,
      ari,
      intentionError,
      send,
      noMail,
    };
  },
});
</script>
