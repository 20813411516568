<template>
  <form>
    <section class="section pt-3 pb-5 mb-5">
      <div class="container">
        <div class="columns">
          <div class="column has-text-centered primary-text">
            <p class="mb-4">お申込みありがとうございました。</p>
            <p class="has-text-danger-dark has-text-weight-bold">（ご注意）補償はまだ開始しておりません。</p>
            <p v-if="hasMailAddress" class="mb-5">お申し込み内容をメールにて送信しましたのでご確認ください。</p>

            <div class="columns is-centered" v-if="isConveni">
              <div class="column is-6-fullhd is-7-widescreen is-8-desktop is-11-tablet is-12-mobile">
                <div class="card mb-5">
                  <header class="card-header card-title">
                    <p class="card-header-title is-size-4"><span class="material-icons accent-color">update</span>コンビニ決済を選択されたお客様へ</p>
                  </header>
                  <div class="card-content has-text-left">
                    <p class="has-text-centered mb-3">以下の内容でお支払いください。</p>
                    <label class="label">ご指定のコンビニエンスストア</label>
                    <p class="mb-3">{{ getConveniText(conveniCode) }}</p>

                    <!-- コンビニごとに表示が異なる -->
                    <!-- セブンイレブン -->
                    <template v-if="conveniCode === constants.CONVENIENCE_STORE.SEVEN_ELEVEN">
                      <label class="label">払込票番号</label>
                      <p class="mb-3">{{ conveniRecipt }}</p>
                      <label class="label">お支払期限</label>
                      <p class="mb-3">{{ conveniPayTerm }}</p>
                      <label class="label">払込票等ＵＲＬ</label>
                      <a class="mb-3" :href="conveniReciptUrl">{{ conveniReciptUrl }}</a>
                    </template>

                    <!-- ファミリーマート -->
                    <template v-if="conveniCode === constants.CONVENIENCE_STORE.FAMILY_MART">
                      <label class="label">第1番号（企業コード）</label>
                      <p class="mb-3">{{ conveniConf }}</p>
                      <label class="label">第2番号（注文番号）</label>
                      <p class="mb-3">{{ conveniRecipt }}</p>
                      <label class="label">お支払期限</label>
                      <p class="mb-3">{{ conveniPayTerm }}</p>
                    </template>

                    <!-- ローソン -->
                    <template v-if="conveniCode === constants.CONVENIENCE_STORE.LAWSON">
                      <label class="label">お客様番号</label>
                      <p class="mb-3">{{ conveniRecipt }}</p>
                      <label class="label">確認番号</label>
                      <p class="mb-3">{{ conveniConf }}</p>
                      <label class="label">お支払期限</label>
                      <p class="mb-3">{{ conveniPayTerm }}</p>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </form>
</template>

<script lang="ts">
import { getPlanList, PlanRequest } from "@/api/plan";
import { useStore } from "@/store";
import { ConveniMutationTypes } from "@/store/modules/conveni";
import { EntryMutationTypes } from "@/store/modules/entry";
import { MailMutationTypes } from "@/store/modules/mail";
import { PlanMutationTypes } from "@/store/modules/plans";
import { StepMutationTypes } from "@/store/modules/steps";
import { getServerTime } from "common/src/api/serverTime";
import constants from "common/src/constants";
import { defineComponent } from "vue";
import { getConveniText } from "common/src/utils/codes";
import { AnotherMutationTypes } from "@/store/modules/another";
import { ErrorsMutationTypes } from "@/store/modules/errors";

export default defineComponent({
  setup() {
    const store = useStore();

    // メールアドレスを入力したかどうか
    const hasMailAddress = store.state.mail.mailAddress !== "";

    // コンビニ払いの場合のみ表示する情報を取得
    const isConveni = store.state.entry.msk01p.tfMshkhh === constants.HARAIKOMI_HOUHOU.CONVENI;
    const conveniCode = store.state.conveni.tfMsconv;
    const conveniConf = store.state.conveni.tfMscono;
    const conveniRecipt = store.state.conveni.tfMsreno;
    const conveniPayTerm = store.state.conveni.tfMspydt;
    const conveniReciptUrl = store.state.conveni.receiptUrl;

    // 画面初期表示タイミングでストアを初期化
    store.commit(EntryMutationTypes.RESET);
    store.commit(MailMutationTypes.RESET);
    store.commit(ConveniMutationTypes.RESET);
    store.commit(StepMutationTypes.RESET);
    store.commit(AnotherMutationTypes.RESET);
    store.commit(ErrorsMutationTypes.RESET_ERRORS);

    // 現在日・プランリストを取得してストアにセット
    getServerTime().then((res) => {
      store.commit(EntryMutationTypes.SET_ENTRY_DATE, res.data.serverDate);
      const planReq: PlanRequest = {
        entryDate: res.data.serverDate,
        productCode: constants.SHOUHIN_CODE.SANKO_LIFE_GUARD,
      };
      getPlanList(planReq).then((res) => store.commit(PlanMutationTypes.SET_PLAN_LIST, res.data.list));
    });

    return {
      hasMailAddress,
      isConveni,
      conveniCode,
      conveniConf,
      conveniRecipt,
      conveniPayTerm,
      conveniReciptUrl,
      constants,
      getConveniText,
    };
  },
});
</script>
