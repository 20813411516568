import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { Ref } from "vue";
import { Router } from "vue-router";

/** リトライ処理かどうかを保持するインターフェース定義 */
interface RefreshAxiosRequestConfig extends AxiosRequestConfig {
  retry?: boolean;
}

/** APIエラーレスポンス型定義 */
export interface ErrorResponse {
  errors: ErrorModel[];
}

/** エラー1件の型定義 */
export interface ErrorModel {
  message: string;
  field: string;
  type: string;
}

/** APIリクエストモジュール作成処理 */
const createInstance = () =>
  axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URI,
    timeout: process.env.VUE_APP_API_TIMEOUT,
    withCredentials: true,
    headers: {
      "X-API-SECRET": process.env.VUE_APP_API_SECRET_KEY,
    },
  });

/** ログアウト処理 */
const unauthorized = (): void => {
  window.sessionStorage.removeItem(accessTokenKey);
  window.sessionStorage.removeItem(refreshTokenKey);
  logout();
};

/** 認証が必要なAPIを呼び出すインスタンス */
const authRequest = createInstance();

/** 認証が不要なAPIを呼び出すインスタンス */
const anonymousRequest = createInstance();

/** リフレッシュ処理プロミス保持変数 */
let refreshPromise: Promise<AxiosResponse> | null;
/** ログインページへのリダイレクト処理 */
let logout: () => void;
/** アクセストークン・リフレッシュトークンキー */
let accessTokenKey = "";
let refreshTokenKey = "";
/** 外部からセットするルーター */
let router: Router | undefined = undefined;
let isLoading: Ref<boolean> | undefined = undefined;

anonymousRequest.interceptors.request.use((config) => {
  if (isLoading !== undefined) isLoading.value = true;
  return config;
});

anonymousRequest.interceptors.response.use(
  (response: AxiosResponse) => {
    if (isLoading !== undefined) isLoading.value = false;
    return response;
  },
  (err: AxiosError) => {
    if (isLoading !== undefined) isLoading.value = false;

    // 500エラー時
    if (err.response?.status === 500) {
      router?.push({ name: "500" });
      return;
    }

    // ネットワークエラー時
    if (err.message === "Network Error") {
      router?.push({ name: "500" });
      return;
    }

    // タイムアウト時
    if (err.code === "ECONNABORTED") {
      router?.push({ name: "408" });
      return;
    }

    return Promise.reject(err);
  }
);

// Request interceptors
// リクエスト前処理
authRequest.interceptors.request.use((config) => {
  const accessToken = window.sessionStorage.getItem(accessTokenKey);
  if (isLoading !== undefined) isLoading.value = true;
  if (accessToken) config.headers["Authorization"] = `Bearer ${accessToken}`;
  return config;
});

// Response interceptors
authRequest.interceptors.response.use(
  (response: AxiosResponse) => {
    if (isLoading !== undefined) isLoading.value = false;
    return response;
  },
  (err: AxiosError) => {
    if (isLoading !== undefined) isLoading.value = false;
    const refConf = err.response?.config as RefreshAxiosRequestConfig;
    if (err.response?.status === 403 && !refConf.retry) {
      refConf.retry = true;
      // 同時にリフレッシュ処理が走ることを防ぐためPromiseを保存
      if (!refreshPromise) {
        // ここだけ、interceptorが走らないようにAPIインスタンスを取り直し
        refreshPromise = createInstance()
          .post("/v1/refresh-token", {
            refreshToken: window.sessionStorage.getItem(refreshTokenKey),
          })
          .then((res) => {
            window.sessionStorage.setItem(accessTokenKey, res.data.accessToken);
            window.sessionStorage.setItem(refreshTokenKey, res.data.refreshToken);
            return res.data;
          })
          .catch(() => {
            unauthorized();
            return null;
          })
          .finally(() => {
            refreshPromise = null;
          });
      }

      return refreshPromise.then((data) => {
        // dataが入っていない = リフレッシュ処理失敗時は再リクエストを行わない
        if (!data) return;

        err.config.headers["Authorization"] = `Bearer ${window.sessionStorage.getItem(accessTokenKey)}`;
        return authRequest.request(err.config);
      });
    }

    // 500エラー時
    if (err.response?.status === 500) {
      router?.push({ name: "500" });
      return;
    }

    // ネットワークエラー時
    if (err.message === "Network Error") {
      router?.push({ name: "500" });
      return;
    }

    // タイムアウト時
    if (err.code === "ECONNABORTED") {
      router?.push({ name: "408" });
      return;
    }

    return Promise.reject(err);
  }
);

/**
 * ログインページへのリダイレクト処理や、各アプリ固有のログアウト処理のセット
 * 当モジュールをcommon内に配置する関係で、ルーターを直接使用することが出来ないため
 * 外部からリダイレクト処理をセットする形式にしている
 * @param logout
 */
const setLogout = (logoutProc: () => void): void => {
  logout = logoutProc;
};

/**
 * アクセストークン・リフレッシュトークンのキーをセットする処理
 * @param access
 * @param refresh
 */
const setTokenKey = (access: string, refresh: string): void => {
  accessTokenKey = access;
  refreshTokenKey = refresh;
};

/**
 * 各画面で使用するルーターをセットする
 * @param r
 */
const setRouter = (r: Router): void => {
  router = r;
};

/**
 * ローディング中であるかどうかを外部からセット
 * @param loading
 */
const setLoading = (loading: Ref<boolean>): void => {
  isLoading = loading;
};

export { authRequest, anonymousRequest, setLogout, setTokenKey, setRouter, unauthorized, setLoading };
