
import { computed, defineComponent } from "vue";
import router from "@/router";
import LoadingIndicator from "common/src/components/LoadingIndicator.vue";

export default defineComponent({
  components: {
    LoadingIndicator,
  },
  setup() {
    const title = computed(() => router.currentRoute.value.meta.title);
    const englishTitle = computed(() => router.currentRoute.value.meta.englishTitle);
    const screenId = computed(() => router.currentRoute.value.meta.screenId);
    const isError = computed(() => !!router.currentRoute.value.meta.errorPage);
    const steps = computed(() => router.currentRoute.value.meta.steps);

    return {
      title,
      englishTitle,
      screenId,
      isError,
      steps,
    };
  },
});
