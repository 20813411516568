import { AxiosResponse } from "axios";
import { anonymousRequest } from "../utils/request";

/**
 * API0130 住所情報取得 リクエストインターフェース
 */
export type addressRequest = {
  /** 郵便番号 */
  zipCode: string;
};

/**
 * API0130 住所情報取得 レスポンスインターフェース
 */
export type addressResponse = {
  /** 郵便番号 */
  tf_ybybno: string;
  /** 都道府県名カナ */
  tf_ybthkn: string;
  /** 市区町村名カナ */
  tf_ybsckn: string;
  /** 町域名カナ */
  tf_ybcikn: string;
  /** 都道府県名漢字 */
  tf_ybthkj: string;
  /** 市区町村名漢字 */
  tf_ybsckj: string;
  /** 町域名漢字 */
  tf_ybcikj: string;
};

/**
 * API0130 住所情報取得
 * @param address
 * @returns
 */
export const getAddress = async (address: addressRequest): Promise<AxiosResponse<addressResponse>> => {
  return await anonymousRequest.get(`/v1/zip-codes/${address.zipCode}`);
};
