<template>
  <div class="column" :class="yClass">
    <div class="field">
      <div class="control">
        <div class="select is-80width">
          <select v-model.number="yearValueData" :disabled="props.disabled">
            <option v-if="props.addEmptyOption"></option>
            <option v-for="y in yearSelectableArray" :value="y" :key="y">{{ y }}</option>
          </select>
        </div>
        <span class="mt-2 ml-1 is-inline-block">年</span>
      </div>
    </div>
    <ErrorField :error="props.yearErrorMessages" />
  </div>
  <div class="column" :class="mClass">
    <div class="field">
      <div class="control">
        <div class="select is-80width">
          <select v-model.number="monthValueData" :disabled="props.disabled">
            <option v-if="props.addEmptyOption"></option>
            <option v-for="m in monthSelectableArray" :value="m" :key="m">
              {{ m.toString().padStart(2, "0") }}
            </option>
          </select>
        </div>
        <span class="mt-2 ml-1 is-inline-block">月</span>
      </div>
    </div>
    <ErrorField :error="props.monthErrorMessages" />
  </div>
  <div class="column" :class="dClass">
    <div class="field">
      <div class="control">
        <div class="select is-80width">
          <select v-model.number="dateValueData" :disabled="props.disabled">
            <option v-if="props.addEmptyOption"></option>
            <option v-for="d in dateSelectableArray" :value="d" :key="d">{{ d.toString().padStart(2, "0") }}</option>
          </select>
        </div>
        <span class="mt-2 ml-1 is-inline-block">日</span>
      </div>
    </div>
    <ErrorField :error="props.dateErrorMessages" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { range } from "../utils/number";
import endOfMonth from "date-fns/endOfMonth";
import ErrorField from "common/src/components/ErrorField.vue";

export default defineComponent({
  components: {
    ErrorField,
  },
  props: {
    /** 空のオプションを追加するかどうか */
    addEmptyOption: {
      type: Boolean,
      default: false,
    },
    /** 年のエラーメッセージ */
    yearErrorMessages: {
      type: Array,
      default: () => [],
    },
    /** 月のエラーメッセージ */
    monthErrorMessages: {
      type: Array,
      default: () => [],
    },
    /** 日のエラーメッセージ */
    dateErrorMessages: {
      type: Array,
      default: () => [],
    },
    /** カラムクラス カラム毎(年・月・日)のクラス定義がない場合、このクラスを使用します */
    generalColumnClass: {
      type: String,
      default: "is-4",
      required: true,
    },
    /** 年カラムにセットするクラス 設定がある場合、generalColumnClassよりこちらのクラスが優先されます */
    yearColumnClass: {
      type: String,
      required: false,
    },
    /** 月カラムにセットするクラス 設定がある場合、generalColumnClassよりこちらのクラスが優先されます */
    monthColumnClass: {
      type: String,
      required: false,
    },
    /** 日カラムにセットするクラス 設定がある場合、generalColumnClassよりこちらのクラスが優先されます */
    dateColumnClass: {
      type: String,
      required: false,
    },
    /** 選択可能年の基準となる年 */
    baseYear: {
      type: Number,
      required: true,
    },
    /** 選択可能上限年 基準年より前の場合は-値を指定してください */
    yearSelectableUpperLimit: {
      type: Number,
      default: 10,
    },
    /** 選択可能下限年 基準年より後の場合は+値を指定してください */
    yearSelectableLowerLimit: {
      type: Number,
      default: 10,
    },
    /** 年の選択値 */
    yearValue: {
      type: Number,
      required: false,
    },
    /** 年を昇順にソートするかどうか */
    yearValueAscSort: {
      type: Boolean,
      default: false,
    },
    /** 月の選択値 */
    monthValue: {
      type: Number,
      required: false,
    },
    /** 日の選択値 */
    dateValue: {
      type: Number,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const yClass = computed(() => props.yearColumnClass ?? props.generalColumnClass);
    const mClass = computed(() => props.monthColumnClass ?? props.generalColumnClass);
    const dClass = computed(() => props.dateColumnClass ?? props.generalColumnClass);

    const yearValueData = computed({
      get: () => props.yearValue,
      set: (v: number | undefined) => context.emit("update:yearValue", v),
    });

    const monthValueData = computed({
      get: () => props.monthValue,
      set: (v: number | undefined) => context.emit("update:monthValue", v),
    });

    const dateValueData = computed({
      get: () => props.dateValue,
      set: (v: number | undefined) => {
        context.emit("update:dateValue", v);
      },
    });

    // 年の選択可能範囲
    const yearSelectableArray = computed(() => {
      if (props.baseYear === 0) return [];
      if (props.yearValueAscSort === true) {
        return range(props.baseYear + props.yearSelectableLowerLimit, props.baseYear + props.yearSelectableUpperLimit, 1).map((n) => n.toString());
      } else {
        return range(props.baseYear + props.yearSelectableUpperLimit, props.baseYear + props.yearSelectableLowerLimit, -1).map((n) => n.toString());
      }
    });

    // 月の選択可能範囲
    const monthSelectableArray = range(1, 12).map((n) => n);

    // 日の選択可能範囲
    // 年・月の選択によって変化
    // 未選択時は1～31
    const dateSelectableArray = computed(() => {
      if (yearValueData.value === undefined || monthValueData.value === undefined) return range(1, 31).map((n) => n);
      const year = yearValueData.value;
      const month = monthValueData.value;
      const startDay = new Date(year, month - 1, 1);
      const lastDay = endOfMonth(startDay);
      const selectable = range(startDay.getDate(), lastDay.getDate()).map((n) => n);

      // 年月の変更によって選択していた日が選択不能になった場合の対処
      if (!selectable.find((d) => d === dateValueData.value)) context.emit("update:dateValue", undefined);

      return selectable;
    });

    return {
      yClass,
      mClass,
      dClass,
      yearValueData,
      monthValueData,
      dateValueData,
      yearSelectableArray,
      monthSelectableArray,
      dateSelectableArray,
      props,
    };
  },
});
</script>
