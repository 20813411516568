<template>
  <div class="insured-item">
    <label class="has-text-weight-bold has-text-dark border-contents-title">被保険者{{ props.insuredNumber }}</label>
    <div class="insured2-info border-contents">
      <label class="label">氏名 漢字</label>
      <div class="columns">
        <div class="column is-half-tablet is-full-mobile">
          <label class="label">姓</label>
          <HyphenParagraph :text="props.tfMhhksjSei" />
        </div>
        <div class="column is-half-tablet is-full-mobile">
          <label class="label">名</label>
          <HyphenParagraph :text="props.tfMhhksjMei" />
        </div>
      </div>

      <label class="label">氏名 カナ</label>
      <div class="columns">
        <div class="column is-half-tablet is-full-mobile">
          <label class="label">姓</label>
          <HyphenParagraph :text="props.tfMhhkskSei" />
        </div>
        <div class="column is-half-tablet is-full-mobile">
          <label class="label">名</label>
          <HyphenParagraph :text="props.tfMhhkskMei" />
        </div>
      </div>

      <label class="label">生年月日</label>
      <div class="columns">
        <div class="column">
          <p v-if="props.tfMhhkbtY !== undefined || props.tfMhhkbtY !== undefined || props.tfMhhkbtY !== undefined">
            {{ props.tfMhhkbtY !== undefined ? props.tfMhhkbtY : "－" }}年
            {{ props.tfMhhkbtM !== undefined ? props.tfMhhkbtM.toString().padStart(2, "0") : "－" }}月
            {{ props.tfMhhkbtD !== undefined ? props.tfMhhkbtD.toString().padStart(2, "0") : "－" }}日
          </p>
          <p v-else>－</p>
        </div>
      </div>

      <label class="label">性別</label>
      <div class="columns">
        <div class="column">
          <HyphenParagraph :text="getGenderText(props.tfMhhksx)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getGenderText } from "common/src/utils/codes";
import HyphenParagraph from "common/src/components/HyphenParagraph.vue";

export default defineComponent({
  components: {
    HyphenParagraph,
  },
  props: {
    /** 被保険者番号 */
    insuredNumber: {
      type: Number,
      required: true,
    },
    /** 被保険者カナ(姓) */
    tfMhhkskSei: {
      type: String,
      required: true,
    },
    /** 被保険者カナ(名) */
    tfMhhkskMei: {
      type: String,
      required: true,
    },
    /** 被保険者漢字(姓) */
    tfMhhksjSei: {
      type: String,
      required: true,
    },
    /** 被保険者漢字(名) */
    tfMhhksjMei: {
      type: String,
      required: true,
    },
    /** 被保険者生年月日 年 */
    tfMhhkbtY: {
      type: Number,
      required: false,
    },
    /** 被保険者生年月日 月 */
    tfMhhkbtM: {
      type: Number,
      required: false,
    },
    /** 被保険者生年月日 日 */
    tfMhhkbtD: {
      type: Number,
      required: false,
    },
    /** 被保険者性別 */
    tfMhhksx: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {
      props,
      getGenderText,
    };
  },
});
</script>
