// Validation関連Util

import constants from "../constants";
import { Ref } from "vue";
import { ErrorResponse } from "./request";
import { ErrorObject } from "@vuelidate/core";

/**
 * フィールドエラーインターフェース
 */
export interface ErrorFieldMessage {
  [key: string]: string[] | ErrorFieldMessage;
}

/**
 * 画面エラーキー
 */
export const GENERAL_FIELD_KEY = "GENERAL";

export const setErrorMessage = (g$: string[], $externalResults: Ref<ErrorFieldMessage>, errors: ErrorResponse): void => {
  const externalErr: ErrorFieldMessage = {};
  errors.errors.forEach((e) => {
    if (e.field === GENERAL_FIELD_KEY) {
      g$.push(e.message);
    } else {
      setObjectError(externalErr, e.field.split("."), 0, e.message);
    }
  });
  $externalResults.value = externalErr;
};

/**
 * エラーオブジェクトへオブジェクトを再帰的に作成しながらセットしていく
 * @param obj
 * @param fieldNameArr
 * @param index
 * @param message
 * @returns
 */
const setObjectError = (obj: ErrorFieldMessage, fieldNameArr: string[], index: number, message: string): void => {
  if (index === fieldNameArr.length - 1) {
    if (obj[fieldNameArr[index]] === undefined) {
      obj[fieldNameArr[index]] = [message];
    } else {
      (obj[fieldNameArr[index]] as string[]).push(message);
    }
    return;
  } else {
    if (obj[fieldNameArr[index]] === undefined) {
      obj[fieldNameArr[index]] = {};
    }
    setObjectError(obj[fieldNameArr[index]] as ErrorFieldMessage, fieldNameArr, index + 1, message);
  }
};

/**
 * Vuelidateのエラーオブジェクトを作成します
 * @param message
 * @returns
 */
export const createErrorObject = (message: string): ErrorObject => ({
  $propertyPath: "",
  $property: "",
  $validator: "",
  $message: message,
  $params: {},
  $pending: true,
  $response: {},
  $uid: new Date().getTime().toString(16) + Math.floor(1000 * Math.random()).toString(16),
});

/**
 * メールアドレス検証
 * @param mail
 * @returns
 */
export const validMail = (mail: string): boolean => {
  if (mail === null || mail === undefined || mail === "") return true;
  return new RegExp("^[A-Z0-9_%*!#$&/^{}+-]+[A-Z0-9._%*!#$&/^{}+-]*[A-Z0-9_%*!#$&/^{}+-]@([A-Z0-9_-]+\\.)+[A-Z]{2,}$", "i").test(mail);
};

/**
 * 名前用の全角カナ検証
 * @param name
 * @returns
 */
export const validZenkakuKanaForName = (name: string): boolean => {
  if (name === null || name === undefined || name === "") return true;
  return new RegExp(
    `^[${constants.ZENKAKU_KANA_CODES}${constants.ZENKAKU_SPACE_CODES}${constants.ZENKAKU_NUMERIC_CODES}${constants.ZENKAKU_ALPHA_CODES}${constants.ZENKAKU_SYMBOL_CODES}${constants.ZENKAKU_ROMAN_NUMERALS}]*$`
  ).test(name);
};

/**
 * 住所用の全角カナ検証
 * @param address
 * @returns
 */
export const validZenkakuKanaForAddress = (address: string): boolean => {
  if (address === null || address === undefined || address === "") return true;
  return new RegExp(
    `^[${constants.ZENKAKU_KANA_CODES}${constants.ZENKAKU_SPACE_CODES}${constants.ZENKAKU_NUMERIC_CODES}${constants.ZENKAKU_ALPHA_CODES}${constants.ZENKAKU_SYMBOL_CODES}${constants.ZENKAKU_ROMAN_NUMERALS}]*$`
  ).test(address);
};

/**
 * 電話番号検証
 * @param mail
 * @returns
 */
export const validTel = (tel: string): boolean => {
  if (tel === null || tel === undefined || tel === "") return true;
  return new RegExp(`^[0-9]+-[0-9]+-[0-9]+$`).test(tel);
};

/**
 * 日付検証(yyyy/MM/dd)
 * @param date
 * @returns
 */
export const validDateYYYY_MM_DD = (date: string): boolean => {
  if (date === null || date === undefined || date === "") return true;

  // フル桁で日付と判定できない場合はそのまま処理を抜ける
  const dateFormatCheck = new RegExp(`^[0-9]{4}/(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])$`).test(date);
  if (dateFormatCheck === false) return false;

  // 日付として正しいかチェック
  const year = Number(date.split("/")[0]);
  const month = Number(date.split("/")[1]) - 1;
  const day = Number(date.split("/")[2]);
  const newDate = new Date(year, month, day);
  return newDate.getFullYear() === year && newDate.getMonth() === month && newDate.getDate() === day;
};

/**
 * 日付検証(18未満でないかどうか)
 * @param date
 * @returns 18以上：True 18未満や日付エラー:False
 */
export const validDateUnderage = (dateY: number, dateM: number, dateD: number): boolean => {
  // 日付型へ変換
  const year = dateY;
  const month = dateM - 1;
  const day = dateD;
  const newDate = new Date(year, month, day);
  if (!(newDate.getFullYear() === year && newDate.getMonth() === month && newDate.getDate() === day)) return false;

  // 年齢を算出
  const today = new Date();
  const thisYearsDate = new Date(today.getFullYear(), month, day);
  console.log(thisYearsDate);
  let age = today.getFullYear() - newDate.getFullYear();
  if (today < thisYearsDate) age--;

  return age >= 18;
};

/**
 * 日付検証(指定日付から指定した月を経過していないかどうか)
 * @param date
 * @param month
 * @returns  指定ヶ月を経過していない：True 指定ヶ月を経過している：False
 */
export const validDateWithinMonth = (date: string, month: number): boolean => {
  // 日付として認識できない場合はそもそもチェックしない
  if (!validDateYYYY_MM_DD(date)) return true;

  // 指定した日付から指定ヶ月後がいつかを算出
  const targetDate = new Date(Number(date.split("/")[0]), Number(date.split("/")[1]) - 1, Number(date.split("/")[2]));
  targetDate.setMonth(targetDate.getMonth() + month);

  return new Date() < targetDate;
};
