import { helpers, required } from "@vuelidate/validators";
import { computed } from "vue-demi";

export type PAY010Form = {
  /** コンビニ */
  conveni: string;
};

export const PAY010FormValidateRule = computed(() => ({
  conveni: {
    required: helpers.withMessage("お支払いされるコンビニエンスストアを選択してください", required),
  },
}));
