import { helpers, sameAs } from "@vuelidate/validators";
import { computed } from "vue";

export type SCR050Form = {
  contractor: boolean;
  location: boolean;
  insured: boolean;
  compensation: boolean;
  contractDetails: boolean;
};

export const SCR050FormValidateRule = computed(() => ({
  contractor: {
    sameAs: helpers.withMessage("確認事項に同意いただけない場合、契約を行うことが出来ません", sameAs(true)),
  },
  location: {
    sameAs: helpers.withMessage("確認事項に同意いただけない場合、契約を行うことが出来ません", sameAs(true)),
  },
  insured: {
    sameAs: helpers.withMessage("確認事項に同意いただけない場合、契約を行うことが出来ません", sameAs(true)),
  },
  compensation: {
    sameAs: helpers.withMessage("確認事項に同意いただけない場合、契約を行うことが出来ません", sameAs(true)),
  },
  contractDetails: {
    sameAs: helpers.withMessage("確認事項に同意いただけない場合、契約を行うことが出来ません", sameAs(true)),
  },
}));
