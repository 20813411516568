
import { computed, defineComponent, ref } from "vue";
import ErrorField from "common/src/components/ErrorField.vue";
import SelectDate from "common/src/components/SelectDate.vue";
import constants from "common/src/constants";
import { helpers, maxLength, or, required, requiredIf } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { validZenkakuKanaForName, validTel, createErrorObject } from "common/src/utils/validate";

export default defineComponent({
  components: {
    ErrorField,
    SelectDate,
  },
  emits: [
    "update:tfMhkysm",
    "update:tfMhhkskSei",
    "update:tfMhhkskMei",
    "update:tfMhhksjSei",
    "update:tfMhhksjMei",
    "update:tfMhhkbtY",
    "update:tfMhhkbtM",
    "update:tfMhhkbtD",
    "update:tfMhhksx",
    "update:tfMhhkt2",
    "update:tfMhhktl",
    "update:tfMhhkma",
    "copy-contract-event",
  ],
  props: {
    /** 誕生日選択可能年の基準となる年 */
    baseYear: {
      type: Number,
      required: true,
    },
    /** 契約者と同じ */
    tfMhkysm: {
      type: String,
      required: true,
    },
    /** 被保険者名 カナ 姓 */
    tfMhhkskSei: {
      type: String,
      required: true,
    },
    /** 被保険者名 カナ 名 */
    tfMhhkskMei: {
      type: String,
      required: true,
    },
    /** 被保険者名 漢字 姓 */
    tfMhhksjSei: {
      type: String,
      required: true,
    },
    /** 被保険者名 漢字 名 */
    tfMhhksjMei: {
      type: String,
      required: true,
    },
    /** 被保険者生年月日 年 */
    tfMhhkbtY: {
      type: Number,
      required: false,
    },
    /** 被保険者生年月日 月 */
    tfMhhkbtM: {
      type: Number,
      required: false,
    },
    /** 被保険者生年月日 日 */
    tfMhhkbtD: {
      type: Number,
      required: false,
    },
    /** 被保険者性別 */
    tfMhhksx: {
      type: String,
      required: true,
    },
    /** 携帯電話番号 */
    tfMhhkt2: {
      type: String,
      required: true,
    },
    /** その他電話番号 */
    tfMhhktl: {
      type: String,
      required: true,
    },
    /** メールアドレス */
    tfMhhkma: {
      type: String,
      required: true,
    },
    /** 法人時は非表示にするためのプロップ */
    isIndividualContentsVisible: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, context) {
    const showMainInsuredModal = ref(false);
    const tfMhkysmData = computed({
      get: () => props.tfMhkysm,
      set: (v: string) => context.emit("update:tfMhkysm", v),
    });
    const contractorSame = computed(() => tfMhkysmData.value === constants.HAIIIE.HAI);
    const tfMhhkskSeiData = computed({
      get: () => props.tfMhhkskSei,
      set: (v: string) => context.emit("update:tfMhhkskSei", v),
    });
    const tfMhhkskMeiData = computed({
      get: () => props.tfMhhkskMei,
      set: (v: string) => context.emit("update:tfMhhkskMei", v),
    });
    const tfMhhksjSeiData = computed({
      get: () => props.tfMhhksjSei,
      set: (v: string) => context.emit("update:tfMhhksjSei", v),
    });
    const tfMhhksjMeiData = computed({
      get: () => props.tfMhhksjMei,
      set: (v: string) => context.emit("update:tfMhhksjMei", v),
    });
    const tfMhhkbtYData = computed({
      get: () => props.tfMhhkbtY,
      set: (v: number | undefined) => context.emit("update:tfMhhkbtY", v),
    });
    const tfMhhkbtMData = computed({
      get: () => props.tfMhhkbtM,
      set: (v: number | undefined) => context.emit("update:tfMhhkbtM", v),
    });
    const tfMhhkbtDData = computed({
      get: () => props.tfMhhkbtD,
      set: (v: number | undefined) => context.emit("update:tfMhhkbtD", v),
    });
    const tfMhhksxData = computed({
      get: () => props.tfMhhksx,
      set: (v: string) => context.emit("update:tfMhhksx", v),
    });
    const tfMhhkt2Data = computed({
      get: () => props.tfMhhkt2,
      set: (v: string) => context.emit("update:tfMhhkt2", v),
    });
    const tfMhhktlData = computed({
      get: () => props.tfMhhktl,
      set: (v: string) => context.emit("update:tfMhhktl", v),
    });
    const tfMhhkmaData = computed({
      get: () => props.tfMhhkma,
      set: (v: string) => context.emit("update:tfMhhkma", v),
    });

    const validator = computed(() => ({
      tfMhhksjSei: {
        required: helpers.withMessage("氏名 漢字 姓を入力してください", requiredIf(!contractorSame.value)),
        max: helpers.withMessage(
          "氏名 漢字 姓は30文字以内で入力してください",
          or(() => contractorSame.value, maxLength(30))
        ),
      },
      tfMhhksjMei: {
        required: helpers.withMessage("氏名 漢字 名を入力してください", requiredIf(!contractorSame.value)),
        max: helpers.withMessage(
          "氏名 漢字 名は30文字以内で入力してください",
          or(() => contractorSame.value, maxLength(30))
        ),
      },
      tfMhhkskSei: {
        required: helpers.withMessage("氏名 カナ 姓を入力してください", requiredIf(!contractorSame.value)),
        max: helpers.withMessage(
          "氏名 カナ 姓は30文字以内で入力してください",
          or(() => contractorSame.value, maxLength(30))
        ),
        zenkakuKana: helpers.withMessage("氏名 カナ 姓は全角カナ・数字・スペース・英字・記号で入力してください", validZenkakuKanaForName),
      },
      tfMhhkskMei: {
        required: helpers.withMessage("氏名 カナ 名を入力してください", requiredIf(!contractorSame.value)),
        max: helpers.withMessage(
          "氏名 カナ 名は30文字以内で入力してください",
          or(() => contractorSame.value, maxLength(30))
        ),
        zenkakuKana: helpers.withMessage("氏名 カナ 名は全角カナ・数字・スペース・英字・記号で入力してください", validZenkakuKanaForName),
      },
      tfMhhkbtY: {
        required: helpers.withMessage("生年月日 年を選択してください", requiredIf(!contractorSame.value)),
      },
      tfMhhkbtM: {
        required: helpers.withMessage("生年月日 月を選択してください", requiredIf(!contractorSame.value)),
      },
      tfMhhkbtD: {
        required: helpers.withMessage("生年月日 日を選択してください", requiredIf(!contractorSame.value)),
      },
      tfMhhksx: {
        required: helpers.withMessage("性別を選択してください", required),
      },
      tfMhhkt2: {
        required: helpers.withMessage("いずれかの電話番号を入力してください", requiredIf(!contractorSame.value && tfMhhktlData.value === "")),
        max: helpers.withMessage(
          "携帯電話番号は13文字以内で入力してください",
          or(() => contractorSame.value, maxLength(13))
        ),
        tel: helpers.withMessage("携帯電話番号はハイフン付きで入力してください", validTel),
      },
      tfMhhktl: {
        required: helpers.withMessage("いずれかの電話番号を入力してください", requiredIf(!contractorSame.value && tfMhhkt2Data.value === "")),
        max: helpers.withMessage(
          "その他電話番号は13文字以内で入力してください",
          or(() => contractorSame.value, maxLength(13))
        ),
        tel: helpers.withMessage("その他電話番号ハイフン付きで入力してください", validTel),
      },
      tfMhhkma: {
        max: helpers.withMessage(
          "メールアドレスは60文字以内で入力してください",
          or(() => contractorSame.value, maxLength(60))
        ),
      },
    }));

    // フィールドエラー設定初期化
    const f$ = useVuelidate(validator, props, {
      $autoDirty: true,
    });

    // 生年月日に関するチェック
    const validBirthDate = () => {
      f$.value.tfMhhkbtY.$reset();
      f$.value.tfMhhkbtY.$touch();
      if (props.tfMhhkbtY !== undefined && props.tfMhhkbtM !== undefined && props.tfMhhkbtD !== undefined) {
        const nowDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        const birthDate = new Date(props.tfMhhkbtY, props.tfMhhkbtM - 1, props.tfMhhkbtD);
        if (nowDate < birthDate) {
          f$.value.tfMhhkbtY.$errors.push(createErrorObject("生年月日に未来の日付が選択されています"));
        }
      }
    };

    // モーダルウィンドウのエラーチェック
    const validMainInsuredModal = () => {
      f$.value.tfMhhksx.$reset();
      f$.value.tfMhhksx.$touch();
    };

    // モーダルウィンドウ キャンセル
    const modalCancel = () => {
      // 入力内容のクリア
      tfMhkysmData.value = constants.HAIIIE.IIE;
      tfMhhksxData.value = "";

      // 画面を閉じる
      showMainInsuredModal.value = false;
    };

    // モーダルウィンドウ OK
    const modalOk = () => {
      // エラーチェック
      validMainInsuredModal();
      if (f$.value.$invalid) return;

      // そのまま画面を閉じる
      showMainInsuredModal.value = false;
    };

    // 申込人と同じクリック時イベント
    const contractorSameClick = () => {
      context.emit("copy-contract-event", tfMhkysmData.value);
    };

    return {
      props,
      tfMhkysmData,
      tfMhhkskSeiData,
      tfMhhkskMeiData,
      tfMhhksjSeiData,
      tfMhhksjMeiData,
      tfMhhkbtYData,
      tfMhhkbtMData,
      tfMhhkbtDData,
      tfMhhksxData,
      tfMhhkt2Data,
      tfMhhktlData,
      tfMhhkmaData,
      constants,
      f$,
      validBirthDate,
      showMainInsuredModal,
      validMainInsuredModal,
      modalCancel,
      modalOk,
      contractorSame,
      contractorSameClick,
    };
  },
});
