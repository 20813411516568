<template>
  <!-- 本文領域 -->
  <form @submit.prevent="confirm">
    <section class="section pt-3 pb-5 mb-5">
      <div class="container">
        <div class="columns">
          <div class="column has-text-centered primary-text">
            <p class="mb-4">
              入力されたメールアドレス宛に確認番号を送信しました。<br />
              メールに記載された確認番号を入力してください。
            </p>
            <div class="columns is-centered mb-5">
              <div class="column is-6">
                <label class="label has-text-left">確認番号</label>
                <div class="field">
                  <div class="control">
                    <input class="input" type="text" v-model="form.authNumber" />
                  </div>
                </div>
                <ErrorField :error="f$.authNumber.$errors" />
                <div class="field">
                  <div class="control">
                    <a class="button is-medium is-fullwidth accent-color-bg px-6" @click="confirm">確認番号を入力して進む</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-centered mb-5">
              <div class="column is-8">
                <div class="notification light-primary-color-bg pt-3 px-4">
                  <p class="has-text-danger-dark"><strong>※メールが届かない場合</strong></p>
                  <p class="has-text-left">5分以上たってもメールが届かない場合、下記をご確認ください。</p>
                  <div class="content is-shadowless has-text-left">
                    <ul>
                      <li>メールアドレスが間違っていませんか？</li>
                      <li>
                        フィルタ設定がされていませんか？<br />
                        <span class="text-info">（※@sanko-life-hoken.comを受信可能ドメインに登録してください）</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column is-6">
                <div class="field">
                  <div class="control">
                    <router-link :to="{ name: 'DisclosureStatement' }" class="button is-medium is-fullwidth prev-btn px-6">
                      前の画面に戻る
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </form>
</template>

<script lang="ts">
import { EmailAcceptRequest, postEmailAcceptRequest } from "@/api/email";
import { SCR020Form, SCR020FormValidateRule } from "@/forms/scr020";
import router from "@/router";
import { useStore } from "@/store";
import { StepMutationTypes } from "@/store/modules/steps";
import useVuelidate from "@vuelidate/core";
import { defineComponent, reactive, ref, UnwrapRef } from "vue";
import ErrorField from "common/src/components/ErrorField.vue";
import axios from "axios";
import { scrollToError } from "common/src/utils/screen";

export default defineComponent({
  components: {
    ErrorField,
  },
  setup() {
    const form: UnwrapRef<SCR020Form> = reactive({
      authNumber: "",
    });

    // フィールドエラーを外部からセットする変数
    const $externalResults = ref({});
    // フィールドエラー設定初期化
    const f$ = useVuelidate(SCR020FormValidateRule, form, {
      $externalResults,
    });

    const store = useStore();

    const confirm = async () => {
      f$.value.$clearExternalResults();
      f$.value.$touch();
      if (f$.value.$invalid) return;

      try {
        // API0220呼び出し
        const req: EmailAcceptRequest = {
          authNumber: form.authNumber,
          serialNo: store.state.entry.security.tfHnuuid,
        };
        await postEmailAcceptRequest(req);

        store.commit(StepMutationTypes.STEP2, true);

        // SCR030 管理番号入力画面へ遷移
        router.push({ name: "ControlNumberInput" });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 403) {
          $externalResults.value = { authNumber: "確認番号がまちがっています" };
          scrollToError();
        }
      }
    };

    return {
      form,
      confirm,
      f$,
    };
  },
});
</script>
