import { AxiosResponse } from "axios";
import { anonymousRequest } from "common/src/utils/request";

/**
 * API0240 賃貸契約情報取得 リクエストインターフェース
 */
export type ControlNumberRequest = {
  /** 管理番号 */
  tf_cnkyno: string;
  /** 申込人・フリガナ */
  tf_cnmskn: string;
  /** 申込人・生年月日 年 */
  tf_cnmstlY: number | undefined;
  /** 申込人・生年月日 月 */
  tf_cnmstlM: number | undefined;
  /** 申込人・生年月日 日 */
  tf_cnmstlD: number | undefined;
};

/**
 * API0240 賃貸契約情報取得 レスポンスインターフェース
 */
export type ControlNumberResponse = {
  /** 管理番号 */
  tf_cnkyno: string;
  /** 申込人・申込人名 */
  tf_cnmskj: string;
  /** 申込人・フリガナ */
  tf_cnmskn: string;
  /** 申込人・生年月日 */
  tf_cnmstl: string;
  /** 目的所在地の郵便番号 */
  tf_cnttyn: string;
  /** 目的所在地の住所 */
  tf_cnttad: string;
  /** 目的所在地の住所カナ */
  tf_cnttak: string;
  /** 目的所在地の建物名 */
  tf_cnttnm: string;
  /** 目的所在地の建物名称カナ */
  tf_cnttnk: string;
  /** 目的所在地の部屋番号 */
  tf_cnhyno: string;
  /** 建物情報・建物構造 */
  tf_cnttkz: string;
  /** 保険契約開始日 */
  tf_cnhksk: string;
  /** コースコード */
  tf_cnhony: string;
  /** 支払区分 */
  tf_cnhshr: string;
  /** 保険料 */
  tf_cnhoho: string;
  /** 代理店ード */
  tf_cndrcd: string;
  /** 支店コード */
  tf_cnstcd: string;
  /** 募集人コード */
  tf_cnbscd: string;
  /** 個人法人区分 */
  tf_cnkjhj: string;
};

/**
 * API0240 賃貸契約情報取得
 * @param req
 * @returns
 */
export const getRentalContract = async (req: ControlNumberRequest): Promise<AxiosResponse<ControlNumberResponse>> => {
  return await anonymousRequest.post(`/v1/rental-contract/${req.tf_cnkyno}`, req);
};
