import { EntryRequest, EntryResponse } from "@/api/entry";
import { Plan } from "@/api/plan";
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { AnotherState, AnotherStore, anotherStore } from "./modules/another";
import { conveniStore, ConveniStore } from "./modules/conveni";
import { EntryStore, entryStore } from "./modules/entry";
import { ErrorState, ErrorsStore, errorsStore } from "./modules/errors";
import { MailState, mailStore, MailStore } from "./modules/mail";
import { planStore, PlanStore } from "./modules/plans";
import { StepState, stepStore, StepStore } from "./modules/steps";

/**
 * 複数のストア(modules内に作成)をまとめるストア
 */
export type RootState = {
  entry: Readonly<EntryRequest>;
  mail: Readonly<MailState>;
  plans: Readonly<Plan[]>;
  steps: Readonly<StepState>;
  conveni: Readonly<EntryResponse>;
  another: Readonly<AnotherState>;
  errors: Readonly<ErrorState>;
};

/**
 * ストアの型定義
 */
export type Store = EntryStore<Pick<RootState, "entry">> &
  MailStore<Pick<RootState, "mail">> &
  PlanStore<Pick<RootState, "plans">> &
  StepStore<Pick<RootState, "steps">> &
  ConveniStore<Pick<RootState, "conveni">> &
  AnotherStore<Pick<RootState, "another">> &
  ErrorsStore<Pick<RootState, "errors">>;

/**
 * ストア作成
 */
export const globalStore = createStore({
  plugins: [
    /**
     * ストア情報はSessionStorageへ格納
     */
    createPersistedState({
      key: "Entry",
      storage: window.sessionStorage,
    }),
  ],
  modules: {
    entry: entryStore,
    mail: mailStore,
    plans: planStore,
    steps: stepStore,
    conveni: conveniStore,
    another: anotherStore,
    errors: errorsStore,
  },
});

/**
 * 各コンポーネントでストアを使用するためのメソッド
 * @returns SessionStorageに格納されたストア
 */
export function useStore(): Store {
  return globalStore as Store;
}
