<template>
  <div class="wrapper">
    <div class="errorpage">
      <h1>503.</h1>
      <h2>Service Unavailable</h2>
      <div class="description">
        <p>現在サービスを利用できません。</p>
        <p>しばらく時間をおいてから、再度アクセスしてください。</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.errorpage {
  margin: 2em;
  color: #3c4858;
}
.errorpage h1 {
  font-size: 7em;
  margin-bottom: 20px;
  font-weight: 700;
}
.errorpage h2 {
  font-size: 1.5em;
  font-weight: 700;
}
.errorpage .description {
  font-size: 0.85em;
  margin-top: 4em;
}
.errorpage .description p {
  margin: 0;
}
</style>
