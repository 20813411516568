<template>
  <form>
    <div class="columns is-centered">
      <div class="column is-6-fullhd is-7-widescreen is-8-desktop is-11-tablet is-12-mobile">
        <p class="has-text-centered my-3">申込内容をご確認いただき、次へお進みください。</p>

        <!-- 目的の所在地 -->
        <div class="card mb-5">
          <header class="card-header card-title">
            <p class="card-header-title is-size-4"><span class="material-icons accent-color">home</span>目的の所在地</p>
          </header>
          <div class="card-content location-info">
            <label class="label">郵便番号</label>
            <div class="columns">
              <div class="column is-5-tablet is-12-mobile">
                <HyphenParagraph :text="location.tfMhhkyn" />
              </div>
            </div>

            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <label class="label">住所</label>
                <HyphenParagraph :text="location.tfMhhka1" />
              </div>
              <div class="column is-half-tablet is-full-mobile">
                <label class="label">建物名</label>
                <HyphenParagraph :text="location.tfMhhka2Tatemono" />
              </div>
            </div>
            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <div class="field">
                  <label class="label">住所カナ</label>
                  <HyphenParagraph :text="location.tfMhhka3" />
                </div>
              </div>
              <div class="column is-half-tablet is-full-mobile">
                <div class="field">
                  <label class="label">建物名カナ</label>
                  <HyphenParagraph :text="location.tfMhhka4" />
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <label class="label">部屋番号</label>
                <HyphenParagraph :text="location.tfMhhka2HeyaBango" />
              </div>
            </div>
          </div>
        </div>

        <!-- 申込人（契約者） -->
        <div class="card mb-5">
          <header class="card-header card-title">
            <p class="card-header-title is-size-4"><span class="material-icons accent-color">person</span>申込人（契約者）</p>
          </header>
          <div class="card-content contractor-info">
            <p class="text-info mb-3 has-text-danger-dark">
                ※申込人様が上記目的の所在地にご入居されない場合は、<br />
                住所が申込人様のご住所になっているかご確認ください。
            </p>
            <label class="label">氏名 漢字</label>
            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <label class="label">姓</label>
                <HyphenParagraph :text="entry.tfMskykjSei" />
              </div>
              <div class="column is-half-tablet is-full-mobile">
                <label class="label">名</label>
                <HyphenParagraph :text="entry.tfMskykjMei" />
              </div>
            </div>

            <label class="label">氏名 カナ</label>
            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <label class="label">姓</label>
                <HyphenParagraph :text="entry.tfMskyknSei" />
              </div>
              <div class="column is-half-tablet is-full-mobile">
                <label class="label">名</label>
                <HyphenParagraph :text="entry.tfMskyknMei" />
              </div>
            </div>

            <label class="label">生年月日</label>
            <div class="columns">
              <div class="column">
                <p v-if="entry.tfMskybtY !== undefined || entry.tfMskybtM !== undefined || entry.tfMskybtD !== undefined">
                  {{ entry.tfMskybtY !== undefined ? entry.tfMskybtY : "－" }}年
                  {{ entry.tfMskybtM !== undefined ? entry.tfMskybtM.toString().padStart(2, "0") : "－" }}月
                  {{ entry.tfMskybtD !== undefined ? entry.tfMskybtD.toString().padStart(2, "0") : "－" }}日
                </p>
                <p v-else>－</p>
              </div>
            </div>

            <label class="label">郵便番号</label>
            <div class="columns">
              <div class="column is-5-tablet is-12-mobile">
                <HyphenParagraph :text="entry.tfMskyyn" />
              </div>
            </div>

            <label class="label">住所</label>
            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <label class="label">住所</label>
                <HyphenParagraph :text="entry.tfMskya1" />
              </div>
              <div class="column is-half-tablet is-full-mobile">
                <label class="label">建物名</label>
                <HyphenParagraph :text="entry.tfMskya2Tatemono" />
              </div>
            </div>
            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <label class="label">住所カナ</label>
                <HyphenParagraph :text="entry.tfMskya3" />
              </div>
              <div class="column is-half-tablet is-full-mobile">
                <label class="label">建物名カナ</label>
                <HyphenParagraph :text="entry.tfMskya4" />
              </div>
            </div>
            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <label class="label">部屋番号</label>
                <HyphenParagraph :text="entry.tfMskya2HeyaBango" />
              </div>
            </div>

            <label class="label">電話番号</label>
            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <div class="field">
                  <label class="label">携帯電話番号</label>
                  <HyphenParagraph :text="entry.tfMskyt2" />
                </div>
              </div>
              <div class="column is-half-tablet is-full-mobile">
                <div class="field">
                  <label class="label">その他電話番号</label>
                  <HyphenParagraph :text="entry.tfMskytl" />
                </div>
              </div>
            </div>

            <label class="label">メールアドレス</label>
            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <HyphenParagraph :text="mailAddress" />
              </div>
            </div>
          </div>
        </div>

        <!-- 被保険者 -->
        <div class="card mb-5">
          <header class="card-header card-title">
            <p class="card-header-title is-size-4"><span class="material-icons accent-color">groups</span>被保険者（ご入居者全員）</p>
          </header>
          <div class="card-content insured-info">
            <p class="text-info has-text-danger-dark">※ご入居されるお客様の入力漏れがないかご確認ください</p>
            <p class="text-info mb-3 has-text-danger-dark">※氏名や生年月日、性別に誤りがないかご確認ください</p>
            <!-- 目的の所有者の代表者 -->
            <div class="insured-item">
              <label class="has-text-weight-bold has-text-dark border-contents-title">目的の所有者の代表者</label>
              <div class="owner-same-contractor-info border-contents">
                <div class="columns">
                  <div class="column is-full-mobile">
                    <input
                      type="checkbox"
                      id="owner-same-contractor-entry"
                      v-model="mainInsured.tfMhkysm"
                      :true-value="constants.HAIIIE.HAI"
                      :false-value="constants.HAIIIE.IIE"
                      class="check-type-1"
                      disabled
                    />
                    <label for="owner-same-contractor-entry" class="has-text-weight-bold has-text-dark border-contents-title-right">申込人と同じ</label>
                  </div>
                </div>
                <label class="label">氏名 漢字</label>
                <div class="columns">
                  <div class="column is-half-tablet is-full-mobile">
                    <label class="label">姓</label>
                    <HyphenParagraph :text="mainInsured.tfMhhksjSei" />
                  </div>
                  <div class="column is-half-tablet is-full-mobile">
                    <label class="label">名</label>
                    <HyphenParagraph :text="mainInsured.tfMhhksjMei" />
                  </div>
                </div>

                <label class="label">氏名 カナ</label>
                <div class="columns">
                  <div class="column is-half-tablet is-full-mobile">
                    <label class="label">姓</label>
                    <HyphenParagraph :text="mainInsured.tfMhhkskSei" />
                  </div>
                  <div class="column is-half-tablet is-full-mobile">
                    <label class="label">名</label>
                    <HyphenParagraph :text="mainInsured.tfMhhkskMei" />
                  </div>
                </div>

                <label class="label">生年月日</label>
                <div class="columns">
                  <div class="column">
                    <p v-if="mainInsured.tfMhhkbtY !== undefined || mainInsured.tfMhhkbtY !== undefined || mainInsured.tfMhhkbtY !== undefined">
                      {{ mainInsured.tfMhhkbtY !== undefined ? mainInsured.tfMhhkbtY : "－" }}年
                      {{ mainInsured.tfMhhkbtM !== undefined ? mainInsured.tfMhhkbtM.toString().padStart(2, "0") : "－" }}月
                      {{ mainInsured.tfMhhkbtD !== undefined ? mainInsured.tfMhhkbtD.toString().padStart(2, "0") : "－" }}日
                    </p>
                    <p v-else>－</p>
                  </div>
                </div>

                <label class="label">性別</label>
                <div class="columns">
                  <div class="column">
                    <HyphenParagraph :text="getGenderText(mainInsured.tfMhhksx)" />
                  </div>
                </div>

                <label class="label">電話番号</label>
                <div class="columns">
                  <div class="column is-half-tablet is-full-mobile">
                    <div class="field">
                      <label class="label">携帯電話番号</label>
                      <HyphenParagraph :text="mainInsured.tfMhhkt2" />
                    </div>
                  </div>
                  <div class="column is-half-tablet is-full-mobile">
                    <div class="field">
                      <label class="label">その他電話番号</label>
                      <HyphenParagraph :text="mainInsured.tfMhhktl" />
                    </div>
                  </div>
                </div>

                <label class="label">メールアドレス</label>
                <div class="columns">
                  <div class="column is-half-tablet is-full-mobile">
                    <HyphenParagraph :text="mainInsured.tfMhhkma" />
                  </div>
                </div>
              </div>
            </div>
            <!-- 被保険者 -->
            <SubInsuredConfirm
              v-for="(v, i) in subInsureds"
              :key="i"
              :insuredNumber="i + 2"
              :tfMhhkskSei="v.tfMhhkskSei"
              :tfMhhkskMei="v.tfMhhkskMei"
              :tfMhhksjSei="v.tfMhhksjSei"
              :tfMhhksjMei="v.tfMhhksjMei"
              :tfMhhkbtY="v.tfMhhkbtY"
              :tfMhhkbtM="v.tfMhhkbtM"
              :tfMhhkbtD="v.tfMhhkbtD"
              :tfMhhksx="v.tfMhhksx"
            />
          </div>
        </div>

        <!-- 申込内容 -->
        <div class="card mb-5">
          <header class="card-header card-title">
            <p class="card-header-title is-size-4"><span class="material-icons accent-color">article</span>申込内容</p>
          </header>
          <div class="card-content application-info">
            <label class="label">申込日</label>
            <div class="columns">
              <div class="column">
                <p>{{ entry.tfMsmsdtY }}年 {{ entry.tfMsmsdtM.toString().padStart(2, "0") }}月 {{ entry.tfMsmsdtD.toString().padStart(2, "0") }}日</p>
              </div>
            </div>

            <label class="label">保険期間</label>
            <label class="label">開始日</label>
            <div class="columns">
              <div class="column">
                <p v-if="entry.tfMsskdtY !== undefined || entry.tfMsskdtM !== undefined || entry.tfMsskdtD !== undefined">
                  {{ entry.tfMsskdtY !== undefined ? entry.tfMsskdtY : "－" }}年
                  {{ entry.tfMsskdtM !== undefined ? entry.tfMsskdtM.toString().padStart(2, "0") : "－" }}月
                  {{ entry.tfMsskdtD !== undefined ? entry.tfMsskdtD.toString().padStart(2, "0") : "－" }}日
                </p>
                <p v-else>－</p>
              </div>
            </div>

            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <div class="columns is-flex">
                  <div class="column is-half-tablet">
                    <label class="label">契約区分</label>
                    <p>新規</p>
                  </div>
                  <div class="column is-half-tablet">
                    <label class="label">建物</label>
                    <p>{{ getKozoText(mainInsured.tfMhkozo) }}</p>
                  </div>
                </div>
              </div>
              <div class="column is-half-tablet is-full-mobile">
                <div class="columns is-flex">
                  <div class="column is-half-tablet">
                    <label class="label">物件</label>
                    <p>{{ getSyoyuText(mainInsured.tfMhsykb) }}</p>
                  </div>
                  <div class="column is-half-tablet">
                    <label class="label">目的</label>
                    <p>{{ getYoutoText(mainInsured.tfMhytkb) }}</p>
                  </div>
                </div>
              </div>
            </div>

            <label class="label">お支払い方法</label>
            <div class="columns">
              <div class="column is-12">
                <p>{{ getHaraikomiText(entry.tfMshkhh) }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- 補償内容 -->
        <div class="card mb-5">
          <header class="card-header card-title">
            <p class="card-header-title is-size-4"><span class="material-icons accent-color">savings</span>補償内容</p>
          </header>
          <div class="card-content compensation-info">
            <div class="columns">
              <div class="column is-8">
                <div class="field">
                  <label class="label">合計保険料</label>
                  <p>{{ commaSplit(selectedPlan.tf_hrhrgk) }}円</p>
                </div>
              </div>
            </div>
            <div class="columns is-block">
              <div class="column is-12 pb-0">
                <label class="label">家財保険</label>
              </div>
              <div class="column is-8">
                <p>保険金額：{{ commaSplit(selectedPlan.tf_hrhkkg) }}万円 | 保険料：{{ commaSplit(selectedPlan.tf_hrhrhk) }}円</p>
              </div>
            </div>
            <div class="columns is-block">
              <div class="column is-12 pb-0">
                <label class="label">賠償責任保険</label>
              </div>
              <div class="column is-8">
                <p>保険金額：{{ commaSplit(selectedPlan.tf_hrhtkg) }}万円 | 保険料：{{ commaSplit(selectedPlan.tf_hrhthk) }}円</p>
              </div>
            </div>
          </div>
        </div>

        <!-- その他保険 -->
        <div class="card mb-5">
          <header class="card-header card-title">
            <p class="card-header-title is-size-4"><span class="material-icons accent-color">more</span>その他保険</p>
          </header>
          <div class="card-content other-insurance-info">
            <div class="other-insured-item">
              <input
                type="checkbox"
                id="other-insured-join"
                class="check-type-1"
                v-model="entry.tfMsthum"
                :true-value="constants.UMU.ARI"
                :false-value="constants.UMU.NASI"
                disabled
              />
              <label for="other-insured-join" class="has-text-weight-bold has-text-dark">他社の保険に加入している</label>
              <div class="other-insured-join-info checked-info">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <div class="field">
                      <div class="control">
                        <input
                          type="checkbox"
                          v-model="thMsthsrArr"
                          :value="constants.HOKEN_SYURUI.HOUSE"
                          id="other-insured-household"
                          class="check-type-1"
                          disabled
                        />
                        <label for="other-insured-household" class="has-text-dark mr-6">家財保険</label>
                        <input
                          type="checkbox"
                          v-model="thMsthsrArr"
                          :value="constants.HOKEN_SYURUI.LIABILITY"
                          id="other-insured-liability"
                          class="check-type-1"
                          disabled
                        />
                        <label for="other-insured-liability" class="has-text-dark">個人賠償責任保険</label>
                      </div>
                    </div>
                  </div>
                  <div class="column is-12">
                    <label class="label">会社名</label>
                    <div class="field">
                      <div class="control">
                        <input class="input" type="text" :value="entry.tfMsthcm" disabled />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 代理店・支店・募集人 -->
        <div class="card mb-5">
          <header class="card-header card-title">
            <p class="card-header-title is-size-4"><span class="material-icons accent-color">apartment</span>代理店・支店・募集人</p>
          </header>
          <div class="card-content location-info">
            <label class="label">代理店コード</label>
            <div class="columns">
              <div class="column is-5-tablet is-12-mobile">
                <HyphenParagraph :text="entry.tfMsdrcd" />
              </div>
            </div>

            <label class="label">支店コード</label>
            <div class="columns">
              <div class="column is-5-tablet is-12-mobile">
                <HyphenParagraph :text="entry.tfMsstcd" />
              </div>
            </div>

            <label class="label">募集人コード</label>
            <div class="columns">
              <div class="column is-5-tablet is-12-mobile">
                <HyphenParagraph :text="entry.tfMsbscd" />
              </div>
            </div>
          </div>
        </div>

        <table class="table border-divider-color is-bordered mx-auto">
          <tbody>
            <tr>
              <td class="has-text-left">申込人の住所・氏名・電話番号は正しく記載されています。</td>
              <td class="w-space-nowrap">
                <input type="checkbox" id="contractor-verify-yes" v-model="form.contractor" class="check-type-1 is-cb-medium" />
                <label for="contractor-verify-yes" class="primary-text">はい</label>
              </td>
            </tr>
            <tr>
              <td class="has-text-left">目的の住所地・建物名・号室は正しく記載されています。</td>
              <td class="w-space-nowrap">
                <input type="checkbox" id="location-verify-yes" v-model="form.location" class="check-type-1 is-cb-medium" />
                <label for="location-verify-yes" class="primary-text">はい</label>
              </td>
            </tr>
            <tr>
              <td class="has-text-left">被保険者（入居者）全員の氏名、生年月日、年齢、性別は正しく記載されています。</td>
              <td class="w-space-nowrap">
                <input type="checkbox" id="insured-verify-yes" v-model="form.insured" class="check-type-1 is-cb-medium" />
                <label for="insured-verify-yes" class="primary-text">はい</label>
              </td>
            </tr>
            <tr>
              <td class="has-text-left">「家財の保険金額」をご家族の構成等を目安に正しく決めました。</td>
              <td class="w-space-nowrap">
                <input type="checkbox" id="compensation-verify-yes" v-model="form.compensation" class="check-type-1 is-cb-medium" />
                <label for="compensation-verify-yes" class="primary-text">はい</label>
              </td>
            </tr>
            <tr>
              <td class="has-text-left">契約内容を建物所有者・管理会社等が必要とするときは代理店を通じて提供することを承知します。</td>
              <td class="w-space-nowrap">
                <input type="checkbox" id="contract-details-verify-yes" v-model="form.contractDetails" class="check-type-1 is-cb-medium" />
                <label for="contract-details-verify-yes" class="primary-text">はい</label>
              </td>
            </tr>
          </tbody>
        </table>
        <ErrorField :error="intentionError" />

        <div class="btn-area field">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-full-mobile">
              <div class="control">
                <router-link :to="{ name: 'ApplicationInput' }" class="button prev-btn is-medium is-fullwidth">内容を修正する</router-link>
              </div>
            </div>
            <div class="column is-5-tablet is-full-mobile">
              <div class="control">
                <a class="button next-btn is-medium is-fullwidth" @click="submit">{{ isConveni ? "決済へ進む" : "申込完了" }} </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { useStore } from "@/store";
import { computed, defineComponent, reactive, Ref, ref, UnwrapRef } from "vue";
import { getGenderText, getHaraikomiText } from "common/src/utils/codes";
import { commaSplit } from "common/src/utils/number";
import { SCR050Form, SCR050FormValidateRule } from "@/forms/scr050";
import constants from "common/src/constants";
import ErrorField from "common/src/components/ErrorField.vue";
import HyphenParagraph from "common/src/components/HyphenParagraph.vue";
import SubInsuredConfirm from "@/components/SubInsuredConfirm.vue";
import useVuelidate from "@vuelidate/core";
import { postEntry } from "@/api/entry";
import router from "@/router";
import axios from "axios";
import { StepMutationTypes } from "@/store/modules/steps";
import { getSyoyuText, getYoutoText, getKozoText } from "common/src/utils/codes";
import { ErrorsMutationTypes } from "@/store/modules/errors";

export default defineComponent({
  components: {
    ErrorField,
    HyphenParagraph,
    SubInsuredConfirm,
  },
  setup() {
    const form: UnwrapRef<SCR050Form> = reactive({
      contractor: false,
      location: false,
      insured: false,
      compensation: false,
      contractDetails: false,
    });
    const store = useStore();
    const entry = store.state.entry.msk01p;
    const mailAddress = store.state.mail.mailAddress;
    const mainInsured = store.state.entry.msk02p[0];
    const subInsureds = store.state.entry.msk02p.slice(1, store.state.entry.msk02p.length);
    const location = store.state.entry.location;
    const selectedPlan = store.state.plans.filter((p) => p.tf_hrplan === entry.tfMshkpl)[0];
    const isConveni = store.state.entry.msk01p.tfMshkhh === constants.HARAIKOMI_HOUHOU.CONVENI;
    // 他保険種類管理配列
    const thMsthsrArr: Ref<string[]> = ref([]);
    thMsthsrArr.value.push(...(store.state.entry.msk01p.tfMsthsr.split("/") ?? []));

    // 全体エラーメッセージ
    const g$: string[] = reactive([]);

    // フィールドエラーを外部からセットする変数
    const $externalResults = ref({});
    // フィールドエラー設定初期化
    const f$ = useVuelidate(SCR050FormValidateRule, form, {
      $externalResults,
    });

    // 確認事項のエラーを１つだけ表示
    const intentionError = computed(() => {
      if (
        f$.value.contractor.$error ||
        f$.value.location.$error ||
        f$.value.insured.$error ||
        f$.value.compensation.$error ||
        f$.value.contractDetails.$error
      ) {
        return [
          ...f$.value.contractor.$errors,
          ...f$.value.location.$errors,
          ...f$.value.insured.$errors,
          ...f$.value.compensation.$errors,
          ...f$.value.contractDetails.$errors,
        ][0].$message;
      }
      return "";
    });

    // 申込処理実行
    const submit = async () => {
      f$.value.$touch();
      if (f$.value.$invalid) return;

      // コンビニ決済時は決済画面にて申込処理を呼び出す
      if (store.state.entry.msk01p.tfMshkhh === constants.HARAIKOMI_HOUHOU.CONVENI) {
        router.push({ name: "ConveniPayment" });
        return;
      }

      try {
        // API0230呼び出し
        await postEntry(store.state.entry);

        store.commit(StepMutationTypes.STEP5, true);

        router.push({ name: "RegistrationCompleted" });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 400) {
          store.commit(ErrorsMutationTypes.SET_ERRORS, err.response.data);
          router.push({ name: "ApplicationInput" });
        }
      }
    };

    return {
      form,
      entry,
      mailAddress,
      mainInsured,
      subInsureds,
      location,
      selectedPlan,
      getGenderText,
      getHaraikomiText,
      constants,
      commaSplit,
      intentionError,
      f$,
      g$,
      submit,
      getSyoyuText,
      getYoutoText,
      getKozoText,
      isConveni,
      thMsthsrArr,
    };
  },
});
</script>
