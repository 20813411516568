<template>
  <div class="insured-item">
    <div id="owner-same-contractor-label">
      <label class="has-text-weight-bold has-text-dark border-contents-title">目的の所有者の代表者（世帯主）</label>
    </div>
    <div class="owner-same-contractor-info border-contents">
      <div class="columns">
        <div id="individual-owner-same-contractor" class="column is-full-mobile" v-show="isIndividualContentsVisible">
          <p class="text-info mb-3 has-text-danger-dark">※目的の所有者の代表者が申込人と同じ場合は「申込人と同じ」にチェックをしてください。</p>
          <input
            type="checkbox"
            id="owner-same-contractor-entry"
            class="check-type-1"
            :true-value="constants.HAIIIE.HAI"
            :false-value="constants.HAIIIE.IIE"
            v-model="tfMhkysmData"
            @change="contractorSameClick"
            @focus="f$.tfMhhksx.$reset(), f$.$clearExternalResults()"
          />
          <label for="owner-same-contractor-entry" class="has-text-weight-bold has-text-dark border-contents-title-right">申込人と同じ（申込人様もご入居される場合はチェックをしてください）</label>
        </div>
        <div id="individual-owner-same-contractor"></div>
      </div>
      <label class="label">
        氏名 漢字
        <span class="text-info has-text-danger-dark">※外国人のお客様の場合は、在留カードの表記通りにご入力ください</span>
      </label>
      <div class="columns">
        <div class="column is-half-tablet is-full-mobile">
          <div class="field">
            <label class="label">姓<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
            <div class="control">
              <input class="input" type="text" v-model="tfMhhksjSeiData" :disabled="contractorSame" />
            </div>
          </div>
          <ErrorField :error="f$.tfMhhksjSei.$errors" />
        </div>
        <div class="column is-half-tablet is-full-mobile">
          <div class="field">
            <label class="label">名<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
            <div class="control">
              <input class="input" type="text" v-model="tfMhhksjMeiData" :disabled="contractorSame" />
            </div>
          </div>
          <ErrorField :error="f$.tfMhhksjMei.$errors" />
        </div>
      </div>

      <label class="label">氏名 カナ</label>
      <div class="columns">
        <div class="column is-half-tablet is-full-mobile">
          <div class="field">
            <label class="label">姓<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
            <div class="control">
              <input class="input" type="text" v-model="tfMhhkskSeiData" :disabled="contractorSame" />
            </div>
          </div>
          <ErrorField :error="f$.tfMhhkskSei.$errors" />
        </div>
        <div class="column is-half-tablet is-full-mobile">
          <div class="field">
            <label class="label">名<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
            <div class="control">
              <input class="input" type="text" v-model="tfMhhkskMeiData" :disabled="contractorSame" />
            </div>
          </div>
          <ErrorField :error="f$.tfMhhkskMei.$errors" />
        </div>
      </div>

      <label class="label">生年月日<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
      <div class="columns">
        <SelectDate
          :generalColumnClass="'is-3-tablet is-full-mobile pr-0'"
          :baseYear="props.baseYear"
          :yearSelectableUpperLimit="0"
          :yearSelectableLowerLimit="-100"
          v-model:yearValue="tfMhhkbtYData"
          v-model:monthValue="tfMhhkbtMData"
          v-model:dateValue="tfMhhkbtDData"
          :disabled="contractorSame"
        />
      </div>
      <ErrorField :error="f$.tfMhhkbtY.$errors" />
      <ErrorField :error="f$.tfMhhkbtM.$errors" />
      <ErrorField :error="f$.tfMhhkbtD.$errors" />

      <label class="label">性別<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
      <div class="columns is-flex">
        <div class="column is-3-tablet is-4-mobile">
          <div class="field">
            <div class="control">
              <input type="radio" id="owner-gender-male" class="radio-type-2" v-model="tfMhhksxData" :value="constants.GENDER.MAN" />
              <label for="owner-gender-male">男性</label>
            </div>
          </div>
        </div>
        <div class="column is-3-tablet is-4-mobile">
          <div class="field">
            <div class="control">
              <input type="radio" id="owner-gender-female" class="radio-type-2" v-model="tfMhhksxData" :value="constants.GENDER.WOMAN" />
              <label for="owner-gender-female">女性</label>
            </div>
          </div>
        </div>
      </div>
      <ErrorField :error="f$.tfMhhksx.$errors" />

      <label class="label">
        電話番号
        <span class="tag is-danger is-light is-rounded ml-2">どちらか必須</span>
        <span class="text-info has-text-danger-dark ml-2">※ハイフン付きで入力してください</span>
      </label>
      <div class="columns">
        <div class="column is-half-tablet is-full-mobile">
          <div class="field">
            <label class="label">携帯電話番号</label>
            <div class="control">
              <input class="input" type="text" v-model="tfMhhkt2Data" @input="f$.tfMhhktl.$touch()" :disabled="contractorSame" />
            </div>
          </div>
          <ErrorField :error="f$.tfMhhkt2.$errors" />
        </div>
        <div class="column is-half-tablet is-full-mobile">
          <div class="field">
            <label class="label">その他電話番号</label>
            <div class="control">
              <input class="input" type="text" v-model="tfMhhktlData" @input="f$.tfMhhkt2.$touch()" :disabled="contractorSame" />
            </div>
          </div>
          <ErrorField :error="f$.tfMhhktl.$errors" />
        </div>
      </div>

      <label class="label">メールアドレス</label>
      <div class="columns">
        <div class="column is-half-tablet is-full-mobile">
          <div class="field">
            <div class="control">
              <input class="input" type="email" v-model="tfMhhkmaData" :disabled="contractorSame" />
            </div>
          </div>
          <ErrorField :error="f$.tfMhhkma.$errors" />
        </div>
      </div>
    </div>
  </div>

  <div id="main-insured-modal" v-if="showMainInsuredModal">
    <div class="card mb-5">
      <header class="card-header card-title">
        <p class="card-header-title is-size-4"><span class="material-icons accent-color">person</span>被保険者</p>
      </header>
      <div class="card-content location-info">
        <p class="text-info mb-5">申込人情報で入力していない以下の追加情報を入力してください。</p>
        <label class="label">性別<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
        <div class="columns is-flex">
          <div class="column is-3-tablet is-4-mobile">
            <div class="field">
              <div class="control">
                <input type="radio" id="owner-gender-male" class="radio-type-2" v-model="tfMhhksxData" :value="constants.GENDER.MAN" />
                <label for="owner-gender-male">男性</label>
              </div>
            </div>
          </div>
          <div class="column is-3-tablet is-4-mobile">
            <div class="field">
              <div class="control">
                <input type="radio" id="owner-gender-female" class="radio-type-2" v-model="tfMhhksxData" :value="constants.GENDER.WOMAN" />
                <label for="owner-gender-female">女性</label>
              </div>
            </div>
          </div>
        </div>
        <ErrorField :error="f$.tfMhhksx.$errors" />

        <div class="btn-area field">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-full-mobile">
              <div class="control">
                <a class="button prev-btn is-medium is-fullwidth" id="main-insured-modal-cancel" @click="modalCancel">キャンセル</a>
              </div>
            </div>
            <div class="column is-5-tablet is-full-mobile">
              <div class="control">
                <a class="button next-btn is-medium is-fullwidth" id="main-insured-modal-ok" @click="modalOk">OK</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import ErrorField from "common/src/components/ErrorField.vue";
import SelectDate from "common/src/components/SelectDate.vue";
import constants from "common/src/constants";
import { helpers, maxLength, or, required, requiredIf } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { validZenkakuKanaForName, validTel, createErrorObject } from "common/src/utils/validate";

export default defineComponent({
  components: {
    ErrorField,
    SelectDate,
  },
  emits: [
    "update:tfMhkysm",
    "update:tfMhhkskSei",
    "update:tfMhhkskMei",
    "update:tfMhhksjSei",
    "update:tfMhhksjMei",
    "update:tfMhhkbtY",
    "update:tfMhhkbtM",
    "update:tfMhhkbtD",
    "update:tfMhhksx",
    "update:tfMhhkt2",
    "update:tfMhhktl",
    "update:tfMhhkma",
    "copy-contract-event",
  ],
  props: {
    /** 誕生日選択可能年の基準となる年 */
    baseYear: {
      type: Number,
      required: true,
    },
    /** 契約者と同じ */
    tfMhkysm: {
      type: String,
      required: true,
    },
    /** 被保険者名 カナ 姓 */
    tfMhhkskSei: {
      type: String,
      required: true,
    },
    /** 被保険者名 カナ 名 */
    tfMhhkskMei: {
      type: String,
      required: true,
    },
    /** 被保険者名 漢字 姓 */
    tfMhhksjSei: {
      type: String,
      required: true,
    },
    /** 被保険者名 漢字 名 */
    tfMhhksjMei: {
      type: String,
      required: true,
    },
    /** 被保険者生年月日 年 */
    tfMhhkbtY: {
      type: Number,
      required: false,
    },
    /** 被保険者生年月日 月 */
    tfMhhkbtM: {
      type: Number,
      required: false,
    },
    /** 被保険者生年月日 日 */
    tfMhhkbtD: {
      type: Number,
      required: false,
    },
    /** 被保険者性別 */
    tfMhhksx: {
      type: String,
      required: true,
    },
    /** 携帯電話番号 */
    tfMhhkt2: {
      type: String,
      required: true,
    },
    /** その他電話番号 */
    tfMhhktl: {
      type: String,
      required: true,
    },
    /** メールアドレス */
    tfMhhkma: {
      type: String,
      required: true,
    },
    /** 法人時は非表示にするためのプロップ */
    isIndividualContentsVisible: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, context) {
    const showMainInsuredModal = ref(false);
    const tfMhkysmData = computed({
      get: () => props.tfMhkysm,
      set: (v: string) => context.emit("update:tfMhkysm", v),
    });
    const contractorSame = computed(() => tfMhkysmData.value === constants.HAIIIE.HAI);
    const tfMhhkskSeiData = computed({
      get: () => props.tfMhhkskSei,
      set: (v: string) => context.emit("update:tfMhhkskSei", v),
    });
    const tfMhhkskMeiData = computed({
      get: () => props.tfMhhkskMei,
      set: (v: string) => context.emit("update:tfMhhkskMei", v),
    });
    const tfMhhksjSeiData = computed({
      get: () => props.tfMhhksjSei,
      set: (v: string) => context.emit("update:tfMhhksjSei", v),
    });
    const tfMhhksjMeiData = computed({
      get: () => props.tfMhhksjMei,
      set: (v: string) => context.emit("update:tfMhhksjMei", v),
    });
    const tfMhhkbtYData = computed({
      get: () => props.tfMhhkbtY,
      set: (v: number | undefined) => context.emit("update:tfMhhkbtY", v),
    });
    const tfMhhkbtMData = computed({
      get: () => props.tfMhhkbtM,
      set: (v: number | undefined) => context.emit("update:tfMhhkbtM", v),
    });
    const tfMhhkbtDData = computed({
      get: () => props.tfMhhkbtD,
      set: (v: number | undefined) => context.emit("update:tfMhhkbtD", v),
    });
    const tfMhhksxData = computed({
      get: () => props.tfMhhksx,
      set: (v: string) => context.emit("update:tfMhhksx", v),
    });
    const tfMhhkt2Data = computed({
      get: () => props.tfMhhkt2,
      set: (v: string) => context.emit("update:tfMhhkt2", v),
    });
    const tfMhhktlData = computed({
      get: () => props.tfMhhktl,
      set: (v: string) => context.emit("update:tfMhhktl", v),
    });
    const tfMhhkmaData = computed({
      get: () => props.tfMhhkma,
      set: (v: string) => context.emit("update:tfMhhkma", v),
    });

    const validator = computed(() => ({
      tfMhhksjSei: {
        required: helpers.withMessage("氏名 漢字 姓を入力してください", requiredIf(!contractorSame.value)),
        max: helpers.withMessage(
          "氏名 漢字 姓は30文字以内で入力してください",
          or(() => contractorSame.value, maxLength(30))
        ),
      },
      tfMhhksjMei: {
        required: helpers.withMessage("氏名 漢字 名を入力してください", requiredIf(!contractorSame.value)),
        max: helpers.withMessage(
          "氏名 漢字 名は30文字以内で入力してください",
          or(() => contractorSame.value, maxLength(30))
        ),
      },
      tfMhhkskSei: {
        required: helpers.withMessage("氏名 カナ 姓を入力してください", requiredIf(!contractorSame.value)),
        max: helpers.withMessage(
          "氏名 カナ 姓は30文字以内で入力してください",
          or(() => contractorSame.value, maxLength(30))
        ),
        zenkakuKana: helpers.withMessage("氏名 カナ 姓は全角カナ・数字・スペース・英字・記号で入力してください", validZenkakuKanaForName),
      },
      tfMhhkskMei: {
        required: helpers.withMessage("氏名 カナ 名を入力してください", requiredIf(!contractorSame.value)),
        max: helpers.withMessage(
          "氏名 カナ 名は30文字以内で入力してください",
          or(() => contractorSame.value, maxLength(30))
        ),
        zenkakuKana: helpers.withMessage("氏名 カナ 名は全角カナ・数字・スペース・英字・記号で入力してください", validZenkakuKanaForName),
      },
      tfMhhkbtY: {
        required: helpers.withMessage("生年月日 年を選択してください", requiredIf(!contractorSame.value)),
      },
      tfMhhkbtM: {
        required: helpers.withMessage("生年月日 月を選択してください", requiredIf(!contractorSame.value)),
      },
      tfMhhkbtD: {
        required: helpers.withMessage("生年月日 日を選択してください", requiredIf(!contractorSame.value)),
      },
      tfMhhksx: {
        required: helpers.withMessage("性別を選択してください", required),
      },
      tfMhhkt2: {
        required: helpers.withMessage("いずれかの電話番号を入力してください", requiredIf(!contractorSame.value && tfMhhktlData.value === "")),
        max: helpers.withMessage(
          "携帯電話番号は13文字以内で入力してください",
          or(() => contractorSame.value, maxLength(13))
        ),
        tel: helpers.withMessage("携帯電話番号はハイフン付きで入力してください", validTel),
      },
      tfMhhktl: {
        required: helpers.withMessage("いずれかの電話番号を入力してください", requiredIf(!contractorSame.value && tfMhhkt2Data.value === "")),
        max: helpers.withMessage(
          "その他電話番号は13文字以内で入力してください",
          or(() => contractorSame.value, maxLength(13))
        ),
        tel: helpers.withMessage("その他電話番号ハイフン付きで入力してください", validTel),
      },
      tfMhhkma: {
        max: helpers.withMessage(
          "メールアドレスは60文字以内で入力してください",
          or(() => contractorSame.value, maxLength(60))
        ),
      },
    }));

    // フィールドエラー設定初期化
    const f$ = useVuelidate(validator, props, {
      $autoDirty: true,
    });

    // 生年月日に関するチェック
    const validBirthDate = () => {
      f$.value.tfMhhkbtY.$reset();
      f$.value.tfMhhkbtY.$touch();
      if (props.tfMhhkbtY !== undefined && props.tfMhhkbtM !== undefined && props.tfMhhkbtD !== undefined) {
        const nowDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        const birthDate = new Date(props.tfMhhkbtY, props.tfMhhkbtM - 1, props.tfMhhkbtD);
        if (nowDate < birthDate) {
          f$.value.tfMhhkbtY.$errors.push(createErrorObject("生年月日に未来の日付が選択されています"));
        }
      }
    };

    // モーダルウィンドウのエラーチェック
    const validMainInsuredModal = () => {
      f$.value.tfMhhksx.$reset();
      f$.value.tfMhhksx.$touch();
    };

    // モーダルウィンドウ キャンセル
    const modalCancel = () => {
      // 入力内容のクリア
      tfMhkysmData.value = constants.HAIIIE.IIE;
      tfMhhksxData.value = "";

      // 画面を閉じる
      showMainInsuredModal.value = false;
    };

    // モーダルウィンドウ OK
    const modalOk = () => {
      // エラーチェック
      validMainInsuredModal();
      if (f$.value.$invalid) return;

      // そのまま画面を閉じる
      showMainInsuredModal.value = false;
    };

    // 申込人と同じクリック時イベント
    const contractorSameClick = () => {
      context.emit("copy-contract-event", tfMhkysmData.value);
    };

    return {
      props,
      tfMhkysmData,
      tfMhhkskSeiData,
      tfMhhkskMeiData,
      tfMhhksjSeiData,
      tfMhhksjMeiData,
      tfMhhkbtYData,
      tfMhhkbtMData,
      tfMhhkbtDData,
      tfMhhksxData,
      tfMhhkt2Data,
      tfMhhktlData,
      tfMhhkmaData,
      constants,
      f$,
      validBirthDate,
      showMainInsuredModal,
      validMainInsuredModal,
      modalCancel,
      modalOk,
      contractorSame,
      contractorSameClick,
    };
  },
});
</script>
