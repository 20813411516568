import { ErrorResponse } from "common/src/utils/request";
import { CommitOptions, Module, MutationTree, Store } from "vuex";
import { RootState } from "..";

export type ErrorState = {
  errors: ErrorResponse | undefined;
};

export const state: ErrorState = {
  errors: undefined,
};

export enum ErrorsMutationTypes {
  SET_ERRORS = "SET_ERRORS",
  RESET_ERRORS = "RESET_ERRORS",
}

export type Mutations<S = ErrorState> = {
  [ErrorsMutationTypes.SET_ERRORS](state: S, errors: ErrorResponse): void;
  [ErrorsMutationTypes.RESET_ERRORS](state: S): void;
};

export const mutations: MutationTree<ErrorState> & Mutations = {
  [ErrorsMutationTypes.SET_ERRORS](state: ErrorState, errors: ErrorResponse) {
    state.errors = errors;
  },
  [ErrorsMutationTypes.RESET_ERRORS](state: ErrorState) {
    state.errors = undefined;
  },
};

export type ErrorsStore<S = ErrorState> = Omit<Store<S>, "commit"> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(key: K, payload?: P, options?: CommitOptions): ReturnType<Mutations[K]>;
};

/**
 * ストア
 */
export const errorsStore: Module<ErrorState, RootState> = {
  state,
  mutations,
};
