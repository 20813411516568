
import { computed, defineComponent, reactive, ref, UnwrapRef } from "vue";
import { useStore } from "@/store";
import { SCR010Form, SCR010FormValidateRule } from "@/forms/scr010";
import useVuelidate from "@vuelidate/core";
import constants from "common/src/constants";
import ErrorField from "common/src/components/ErrorField.vue";
import { EmailRequest, postEmailRequest } from "@/api/email";
import axios from "axios";
import { setErrorMessage } from "common/src/utils/validate";
import { ErrorResponse } from "common/src/utils/request";
import { scrollToError } from "common/src/utils/screen";
import { StepMutationTypes } from "@/store/modules/steps";
import router from "@/router";
import { EntryMutationTypes } from "@/store/modules/entry";
import { MailMutationTypes } from "@/store/modules/mail";
import { AnotherMutationTypes } from "@/store/modules/another";

export default defineComponent({
  components: {
    ErrorField,
  },
  setup() {
    const importantRead = ref(false);
    // フォーム変数作成
    const form: UnwrapRef<SCR010Form> = reactive({
      tf_msjyjk: false,
      target_verify: false,
      earthquake_verify: false,
      notification_verify: false,
      another_contract: null,
      tf_mskyma: null,
    });

    // ストア使用時はuseStoreを呼び出す
    const store = useStore();

    // 一度、当画面の処理が完了したら初期表示時にチェックを行う
    if (store.state.steps.step1) {
      form.tf_msjyjk = true;
      form.target_verify = true;
      form.earthquake_verify = true;
      form.notification_verify = true;
      importantRead.value = true;
    }
    // ストアから他保険を復元
    if (store.state.another.hasAnother != null) {
      form.another_contract = store.state.another.hasAnother;
    }
    // ストアからメールアドレスを復元
    if (store.state.mail.mailAddress) {
      form.tf_mskyma = store.state.mail.mailAddress;
    }
    // 当社保険加入状況あり時の定数
    const ari = constants.ARI.ARI;

    // 全体エラーメッセージ
    const g$: string[] = reactive([]);

    // フィールドエラーを外部からセットする変数
    const $externalResults = ref({});
    // フィールドエラー設定初期化
    const f$ = useVuelidate(SCR010FormValidateRule, form, {
      $externalResults,
    });

    // 意向確認のエラーを１つだけ表示
    const intentionError = computed(() => {
      if (f$.value.target_verify.$error || f$.value.earthquake_verify.$error || f$.value.notification_verify.$error) {
        return [...f$.value.target_verify.$errors, ...f$.value.earthquake_verify.$errors, ...f$.value.notification_verify.$errors][0].$message;
      }
      return "";
    });

    // 本人確認リクエストのメールを送付します
    const send = async () => {
      f$.value.$clearExternalResults();

      // メールアドレスが必須なのは本人確認リクエストを行う場合のみなので手動チェック
      if (form.tf_mskyma === "" || form.tf_mskyma === null) {
        $externalResults.value = { tf_mskyma: "メールアドレスを入力してください" };
      }
      f$.value.$touch();
      if (f$.value.$invalid) {
        scrollToError();
        return;
      }

      try {
        // API0210呼び出し
        const req: EmailRequest = {
          mailAddress: form.tf_mskyma ?? "",
        };
        const res = await postEmailRequest(req);

        // ストアへシリアル番号をCommit
        store.commit(EntryMutationTypes.SET_SERIAL, res.serialNo);
        store.commit(MailMutationTypes.SET_MAIL, req.mailAddress);
        store.commit(StepMutationTypes.STEP1, true);
        store.commit(AnotherMutationTypes.SET_ANOTHER, form.another_contract);

        // SCR020メールアドレス確認画面へ遷移
        router.push({ name: "ConfirmEmailAddress" });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 400) {
          setErrorMessage(g$, $externalResults, err.response?.data as ErrorResponse);
        }
      }
    };

    // メールアドレスがない方をクリック時
    const noMail = () => {
      f$.value.$clearExternalResults();
      f$.value.$touch();
      if (f$.value.$invalid) return;

      store.commit(StepMutationTypes.STEP1, true);
      store.commit(StepMutationTypes.STEP2, true);

      // SCR030管理番号入力画面へ移動
      router.push({ name: "ControlNumberInput" });
    };

    return {
      importantRead,
      form,
      f$,
      g$,
      ari,
      intentionError,
      send,
      noMail,
    };
  },
});
