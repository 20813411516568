<template>
  <p :class="props.generalClass">{{ props.text !== "" && props.text !== undefined && props.text !== null ? props.text : "－" }}</p>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: {
      type: String,
      required: false,
    },
    generalClass: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    return {
      props,
    };
  },
});
</script>
