<template>
  <template v-if="isError">
    <router-view />
  </template>
  <template v-else>
    <!-- ヘッダー -->
    <header class="pageheader">
      <span class="company-name">SANKO少額短期保険</span>
      <span class="screen-id is-hidden-mobile">{{ screenId }}</span>
      <h1 class="has-text-weight-medium mt-5">{{ title }}</h1>
      <h2 class="has-text-weight-light">{{ englishTitle }}</h2>
    </header>

    <main>
      <!-- ナビゲーション -->
      <ul class="stepper">
        <li :class="{ 'is-active': steps === 1 }"><span class="counter">1</span><span>TOP</span></li>
        <li :class="{ 'is-active': steps === 2 }">
          <span class="counter">2</span><span>メール<br />アドレス<br />確認</span>
        </li>
        <li :class="{ 'is-active': steps === 3 }">
          <span class="counter">3</span><span>管理番号<br />入力</span>
        </li>
        <li :class="{ 'is-active': steps === 4 }">
          <span class="counter">4</span><span>申込内容<br />入力</span>
        </li>
        <li :class="{ 'is-active': steps === 5 }"><span class="counter">5</span><span>内容確認</span></li>
        <li :class="{ 'is-active': steps === 6 }">
          <span class="counter">6</span><span>お支払い<br />情報入力</span>
        </li>
        <li :class="{ 'is-active': steps === 7 }"><span class="counter">7</span><span>完了</span></li>
      </ul>
      <LoadingIndicator />
      <router-view v-slot="{ Component }">
        <transition name="router-transition">
          <component :is="Component" />
        </transition>
      </router-view>
    </main>

    <!-- フッター -->
    <footer>
      <span>Copyright (C) 2008.株式会社 SANKO少額短期保険</span>
    </footer>
  </template>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import router from "@/router";
import LoadingIndicator from "common/src/components/LoadingIndicator.vue";

export default defineComponent({
  components: {
    LoadingIndicator,
  },
  setup() {
    const title = computed(() => router.currentRoute.value.meta.title);
    const englishTitle = computed(() => router.currentRoute.value.meta.englishTitle);
    const screenId = computed(() => router.currentRoute.value.meta.screenId);
    const isError = computed(() => !!router.currentRoute.value.meta.errorPage);
    const steps = computed(() => router.currentRoute.value.meta.steps);

    return {
      title,
      englishTitle,
      screenId,
      isError,
      steps,
    };
  },
});
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

/*———————————
全体的な設定
———————————*/
body {
  color: #212121; /* PRIMARY TEXT */
}
main {
  padding: 8em 1em 0;
  min-height: calc(100vh - 56px); /* 100vh - footerのheight*/
}

/* 画面遷移時のエフェクト */
.router-transition-enter-active {
  animation: fadeIn 1s;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* カラーパレット */
.dark-primary-color {
  color: #d32f2f;
}
.light-primary-color {
  color: #ffcdd2;
}
.primary-color {
  color: #f44336;
}
.accent-color {
  color: #4caf50;
}
.divider-color {
  color: #bdbdbd;
}
.primary-text {
  color: #212121;
}
.secondary-text {
  color: #757575;
}

.primary-color-bg {
  background-color: #f44336;
  color: #ffffff;
}
.light-primary-color-bg {
  background-color: #ffcdd2;
  color: #212121;
}
.accent-color-bg {
  background-color: #4caf50;
  color: #ffffff;
}

/* アイコン */
.material-icons {
  display: inline-flex;
  vertical-align: middle;
  margin-right: 5px;
}

/*———————————
汎用クラス
———————————*/
.d-initial {
  display: initial !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.w-space-normal {
  white-space: normal !important;
}
.w-space-nowrap {
  white-space: nowrap !important;
}
.h-auto {
  height: auto !important;
}

/*———————————
ラジオボタン
———————————*/
/*type-2*/
input[type="radio"].radio-type-2 {
  display: none;
}
input[type="radio"].radio-type-2 + label {
  position: relative;
  padding-left: 25px;
}
input[type="radio"].radio-type-2 + label::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 2px;
  left: 0px;
  width: 20px;
  height: 20px;
  border: 2px solid #757575; /* SECONDARY TEXT */
  border-radius: 50%;
  transition: 0.2s;
}
input[type="radio"]:checked.radio-type-2 + label::before {
  border: 2px solid #4caf50; /* ACCENT COLOR */
}
input[type="radio"]:disabled:not(:checked).radio-type-2 + label::before {
  background: #757575;
}
input[type="radio"].radio-type-2 + label::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 7px;
  left: 5px;
  width: 10px;
  height: 10px;
  background-color: #4caf50; /* ACCENT COLOR */
  border-radius: 50%;
  transform: scale(0);
  transition: 0.25s;
}
input[type="radio"]:checked.radio-type-2 + label::after {
  transform: scale(1);
}

/*———————————
チェックボックス
———————————*/
input[type="checkbox"].check-type-1,
input[type="radio"].check-type-1 {
  display: none;
}
input[type="checkbox"].check-type-1 + label,
input[type="radio"].check-type-1 + label {
  position: relative;
  padding-left: 20px;
}
input[type="checkbox"].check-type-1 + label::before,
input[type="radio"].check-type-1 + label::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 4px;
  left: 2px;
  width: 16px;
  height: 16px;
  border: 2px solid #bdbdbd; /* DIVIDER COLOR */
  border-radius: 2px;
  transition: 0.2s;
  background-color: #ffffff;
}
input[type="checkbox"]:disabled.check-type-1 + label::before,
input[type="radio"]:disabled.check-type-1 + label::before {
  background-color: #bdbdbd;
}
input[type="checkbox"]:checked.check-type-1 + label::before,
input[type="radio"]:checked.check-type-1 + label::before {
  border: 8px solid #4caf50; /* ACCENT COLOR */
}
/* チェックマーク */
input[type="checkbox"].check-type-1 + label::after,
input[type="radio"].check-type-1 + label::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 11px;
  left: 3px;
  width: 0;
  height: 0;
  transform: rotate(-45deg);
  transform-origin: left top;
  border-left: 2px solid #ffffff; /* TEXT/ICONS */
  border-bottom: 2px solid #ffffff; /* TEXT/ICONS */
  visibility: hidden;
}
input[type="checkbox"]:checked.check-type-1 + label::after,
input[type="radio"]:checked.check-type-1 + label::after {
  animation: check 0.175s ease 0.2s 1 normal forwards;
}

/* サイズ指定 */
input[type="checkbox"].check-type-1.is-cb-medium + label,
input[type="radio"].check-type-1.is-cb-medium + label {
  padding-left: 24px;
}
input[type="checkbox"].check-type-1.is-cb-medium + label::before,
input[type="radio"].check-type-1.is-cb-medium + label::before {
  top: 2px;
  width: 20px;
  height: 20px;
}
input[type="checkbox"]:checked.check-type-1.is-cb-medium + label::before,
input[type="radio"]:checked.check-type-1.is-cb-medium + label::before {
  border-width: 10px;
}
input[type="checkbox"].check-type-1.is-cb-medium + label::after,
input[type="radio"].check-type-1.is-cb-medium + label::after {
  left: 5px;
}

@keyframes check {
  0% {
    visibility: visible;
    width: 0;
    height: 0;
  }
  40% {
    width: 0;
    height: 8px;
  }
  100% {
    visibility: visible;
    width: 12px;
    height: 8px;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*———————————
テキストボックス
———————————*/
.input.is-75width {
  width: 75%;
}

/*———————————
セレクトボックス
———————————*/
/* デフォルトのアイコンを上書き */
.select:not(.is-multiple):not(.is-loading)::after,
.select:not(.is-multiple):not(.is-loading):hover::after {
  border-color: #4caf50; /* ACCENT COLOR */
}
.select.is-80width {
  width: 80%;
}
.select.is-80width select {
  width: 100%;
}

/*———————————
テーブル
———————————*/
.table.border-divider-color,
.table.border-divider-color th,
.table.border-divider-color td {
  border-color: #bdbdbd;
}

/*———————————
影の設定
———————————*/
header,
.content,
footer {
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 30%);
}

/*———————————
ヘッダー
———————————*/
.pageheader {
  margin: 0;
  padding: 4px 0 0;
  color: #ffffff; /* TEXT/ICONS */
  background-color: #f44336; /* PRIMARY COLOR */
  text-align: center;
  position: fixed;
  width: 100%;
  z-index: 9999;
}
.pageheader .company-name {
  position: absolute;
  left: 10px;
  font-size: 1.2em;
}
.pageheader .screen-id {
  position: absolute;
  right: 10px;
}
.pageheader h1 {
  color: #ffffff; /* TEXT/ICONS */
  font-size: 32px;
}
.pageheader h2 {
  color: #ffffff; /* TEXT/ICONS */
  line-height: normal;
  font-size: 24px;
  padding: 0 0 10px;
  margin: 0;
}

/*———————————
ステッパー
———————————*/
.stepper {
  width: 100%;
  max-width: 760px;
  margin: 1em auto 0.5em;
  display: table;
  table-layout: fixed;
  padding: 0;
}
.stepper > li {
  text-align: center;
  display: table-cell;
  position: relative;
  color: #4caf50; /* ACCENT COLOR */
}
/* 数字付きの丸いアイコン */
.stepper > li > .counter {
  margin: 0 auto 5px;
  background-color: #4caf50; /* ACCENT COLOR */
  color: #ffffff; /* TEXT/ICONS */
  width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
}

/* 未完了ステップのテキスト */
.stepper > li.is-active ~ li {
  color: #757575; /* SECONDARY TEXT */
}
/* ステップの数字 */
.stepper > li.is-active ~ li > .counter {
  background-color: #bdbdbd; /* DIVIDER COLOR */
}
/* テキスト全般 */
.stepper > li span {
  font-size: 0.75em;
  font-weight: bold;
  display: block;
  line-height: 1.4;
}
/* 丸いアイコンを繋ぐ線 */
.stepper > li:after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #4caf50; /* ACCENT COLOR */
  position: absolute;
  top: 16px;
  left: 50%;
  z-index: -1;
}
/* 未完了ステップの線 */
.stepper > li.is-active ~ li:after,
.stepper > li.is-active:after {
  background-color: #bdbdbd; /* DIVIDER COLOR */
}
/* 一番最後の丸いアイコンには線を引かない */
.stepper > li:last-child:after {
  display: none;
}

/*———————————
本文
———————————*/

/* 見出し */
.heading-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 意向確認 */
.verify-info:not(:last-child) {
  margin-bottom: 2em;
}

/* カード */
.card-title {
  box-shadow: none;
}
.card-title .card-header-title {
  padding: 1.5rem 0.5rem 0.5rem;
  margin: 0 1rem;
  border-bottom: 1px solid #bdbdbd; /* DIVIDER COLOR */
}

.text-info {
  font-size: 0.85em !important;
}

/* 枠線付きの入力項目 */
.border-contents {
  padding: 1em;
  margin-top: -1em;
  border: 1px solid #bdbdbd; /* DIVIDER COLOR */
}
.border-contents-title,
.border-contents-title-right {
  background-color: #ffffff; /* カードの背景色に合わせる */
  padding-right: 20px;
  padding-left: 10px;
  margin-left: 10px;
  display: inline;
  position: relative;
  z-index: 2;
}
.border-contents-title-right {
  margin-left: -6px;
}

/* 複数項目持ちのラベル行 */
.label-row {
  display: block;
  margin-bottom: 0.5em;
}
.label-row label {
  display: inline;
  position: relative;
}
.label-row .label-right {
  margin-left: 10px;
}

/* 必須アイコン */
label.required::after {
  content: "必須";
  color: #ffffff; /* TEXT/ICONS */
  background-color: red; /* 必須アイコンは赤にしておく */
  border-radius: 5px;
  font-size: 80%;
  padding: 2px 5px;
  margin-left: 8px;
  vertical-align: middle;
}

/*———————————
申込人（契約者）情報
———————————*/
.contents-transition-enter-active {
  animation: fadeIn 0.5s;
}

.contents-transition-leave-active {
  animation: fadeOut 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/*———————————
目的の所在地
———————————*/
.contractor-same-address-item {
  margin-top: 50px;
  margin-bottom: 50px;
}

.contractor-same-address-contents {
  margin-top: 1em;
}

/*———————————
電話番号
———————————*/
#individual-phone-number,
#corporate-phone-number {
  margin-bottom: 24px;
}

/*———————————
被保険者
———————————*/
.insured-item {
  margin-bottom: 20px;
}
#owner-same-contractor:checked + label + div {
  animation: fadeout 0.5s;
  display: none;
}
#owner-same-contractor + label + div {
  animation: fadein 0.5s;
  display: block;
}

/*———————————
申込人と同じ
———————————*/
#corporate-owner-same-contractor {
  margin-bottom: 12px;
}

/*———————————
被保険者用モーダルウィンドウ
———————————*/
#main-insured-modal {
  /*要素を重ねた時の順番*/
  z-index: 9;

  /*画面全体を覆う設定*/
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  /*画面の中央に要素を表示させる設定*/
  align-items: center;
  justify-content: center;
  animation: fadein 0.5s;
  display: flex;
}

#main-insured-modal .card {
  z-index: 10;
  width: 50%;
  padding: 1em;
}

/*———————————
申込内容
———————————*/
/* コンビニのロゴ */
.payment-logo img {
  width: 80px;
}

/*———————————
補償内容
———————————*/

/*———————————
その他保険
———————————*/
.other-insured-join-info {
  padding: 0.5em 1.2em 0;
}
#other-insured-join + label + div {
  animation: fadeout 0.5s;
  display: none;
}
#other-insured-join:checked + label + div {
  animation: fadein 0.5s;
  display: block;
}

/*———————————
次へボタンとか
———————————*/
.btn-area {
  text-align: center;
  margin: 30px auto;
}
.btn-area button {
  padding: 10px 40px;
}
.prev-btn {
  background-color: #999999;
  color: #ffffff;
}
.next-btn {
  color: #ffffff; /* TEXT/ICONS */
  background-color: #4caf50; /* ACCENT COLOR */
}

/*———————————
フッター
———————————*/
footer {
  margin: 0;
  padding: 1em 0;
  text-align: center;
  color: #ffffff; /* TEXT /ICONS */
  background-color: #f44336; /* PRIMARY COLOR */
  height: 56px;
}
</style>
