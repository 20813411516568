import { AxiosResponse } from "axios";
import { anonymousRequest } from "common/src/utils/request";

/**
 * API0160 プランリスト取得API リクエストモデル
 */
export type PlanRequest = {
  /** 申込日 */
  entryDate: string;
  /** 商品コード */
  productCode: string;
};

/**
 * API0160 プランリスト取得API レスポンスモデル
 */
export type PlanResponse = {
  list: Plan[];
};

/**
 * API0160 プラン モデル
 */
export type Plan = {
  /** SEQ */
  tf_hrseqn: number;
  /** 料率適用開始日 */
  tf_hrtkys: string;
  /** 料率適用終了日 */
  tf_hrtkye: string;
  /** 商品コード */
  tf_hrshcd: string;
  /** 商品名称 */
  tf_hrshnm: string;
  /** プラン */
  tf_hrplan: string;
  /** 補償名称 */
  tf_hrplnm: string;
  /** 帳票印字用補償名称 */
  tf_hrprnm: string;
  /** 年齢FROM */
  tf_hragfr: number;
  /** 年齢TO */
  tf_hragto: number;
  /** 性別 */
  tf_hrhrsx: string;
  /** 保険料 */
  tf_hrhrhk: number;
  /** 付帯保険料 */
  tf_hrhthk: number;
  /** 合計金額 */
  tf_hrhrgk: number;
  /** 新規加入時上限年齢 */
  tf_hrjgag: number;
  /** 保険金額（万円） */
  tf_hrhkkg: number;
  /** 付帯保険金額（万円） */
  tf_hrhtkg: number;
};

/**
 * API0160 プランリスト取得
 * @param req
 * @returns
 */
export const getPlanList = async (req: PlanRequest): Promise<AxiosResponse<PlanResponse>> => {
  return await anonymousRequest.get(`/v1/products/${req.productCode}/plans?q=${req.entryDate}`);
};
