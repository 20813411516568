import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { setRouter } from "common/src/utils/request";
import { getServerTime } from "common/src/api/serverTime";
import { globalStore } from "./store";
import { EntryMutationTypes } from "./store/modules/entry";
import { getPlanList, PlanRequest } from "./api/plan";
import { PlanMutationTypes } from "./store/modules/plans";
import constants from "common/src/constants";

const app = createApp(App);

app.use(router);

app.use(globalStore);

if (globalStore.state.entry.msk01p.tfMsmsdtY === 0) {
  // 現在日・プランリストを取得してストアにセット
  getServerTime().then((res) => {
    globalStore.commit(EntryMutationTypes.SET_ENTRY_DATE, res.data.serverDate);
    const planReq: PlanRequest = {
      entryDate: res.data.serverDate,
      productCode: constants.SHOUHIN_CODE.SANKO_LIFE_GUARD,
    };
    getPlanList(planReq).then((res) => globalStore.commit(PlanMutationTypes.SET_PLAN_LIST, res.data.list));
  });
}

setRouter(router);

app.mount("#app");
