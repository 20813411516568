import { helpers, maxLength, required } from "@vuelidate/validators";
import { validZenkakuKanaForName } from "common/src/utils/validate";
import { computed } from "vue";

/**
 * SCR030 管理番号入力画面フォーム バリデーションルール
 */
export const SCR030FormValidateRule = computed(() => ({
  tf_cnkyno: {
    required: helpers.withMessage("管理番号を入力してください", required),
    max: helpers.withMessage(({ $params }) => `管理番号は${($params as { max: number }).max}文字以内で入力してください`, maxLength(8)),
  },
  tf_cnmskn: {
    required: helpers.withMessage("氏名カナを入力してください", required),
    max: helpers.withMessage(({ $params }) => `氏名カナは${($params as { max: number }).max}文字以内で入力してください`, maxLength(100)),
    zenkakuKana: helpers.withMessage("氏名カナは全角カナ・数字・スペース・英字・記号で入力してください", validZenkakuKanaForName),
  },
  tf_cnmstlY: {
    required: helpers.withMessage("生年月日 年を入力してください", required),
  },
  tf_cnmstlM: {
    required: helpers.withMessage("生年月日 月を入力してください", required),
  },
  tf_cnmstlD: {
    required: helpers.withMessage("生年月日 日を入力してください", required),
  },
}));
