import { CommitOptions, Module, MutationTree, Store } from "vuex";
import { RootState } from "..";

export type MailState = {
  mailAddress: string;
};

/**
 * Vuexによる管理を行う対象
 */
export const state: MailState = { mailAddress: "" };

/**
 * stateの状態を変更するためのMutation名定義
 * 各Storeをnamespacedにしないため、ユニークなMutation名となるようにする
 */
export enum MailMutationTypes {
  /**
   * プランセット処理
   */
  SET_MAIL = "SET_MAIL",
  /**
   * 初期化処理
   */
  RESET = "RESET_MAIL",
}

/**
 * Mutation定義
 */
export type Mutations<S = MailState> = {
  [MailMutationTypes.SET_MAIL](state: S, mail: string): void;
  [MailMutationTypes.RESET](state: S): void;
};

/**
 * Mutation実装
 */
export const mutations: MutationTree<MailState> & Mutations = {
  [MailMutationTypes.SET_MAIL](state: MailState, mail: string) {
    state.mailAddress = mail;
  },
  [MailMutationTypes.RESET](state: MailState) {
    state.mailAddress = "";
  },
};

/**
 * ストアの型定義
 */
export type MailStore<S = MailState> = Omit<Store<S>, "commit"> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(key: K, payload?: P, options?: CommitOptions): ReturnType<Mutations[K]>;
};

/**
 * ストア
 */
export const mailStore: Module<MailState, RootState> = {
  state,
  mutations,
};
