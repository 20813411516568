<template>
  <!-- 本文領域 -->
  <div class="columns is-centered">
    <div class="column is-6-fullhd is-7-widescreen is-8-desktop is-11-tablet is-12-mobile">
      <!-- コンビニ決済 -->
      <div class="card mb-5">
        <header class="card-header card-title">
          <p class="card-header-title is-size-4"><span class="material-icons accent-color">update</span>コンビニ決済</p>
        </header>
        <div class="card-content contractor-info">
          <label class="label">店舗<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
          <p class="mb-3">お申し込み完了時に発行される「確認番号や受付番号」をプリントやメモをして下記店舗にてお支払いいただけます。</p>
          <p class="mb-3">お支払いされるコンビニエンスストアをお選びください。</p>
          <div class="columns">
            <div class="column is-4-tablet is-12-mobile">
              <div class="field">
                <div class="control">
                  <input
                    type="radio"
                    id="convenience-seven"
                    class="radio-type-2"
                    v-model="form.conveni"
                    :value="constants.CONVENIENCE_STORE.SEVEN_ELEVEN"
                  />
                  <label for="convenience-seven">セブンイレブン</label>
                </div>
              </div>
            </div>
            <div class="column is-4-tablet is-12-mobile">
              <div class="field">
                <div class="control">
                  <input
                    type="radio"
                    id="convenience-familymart"
                    class="radio-type-2"
                    v-model="form.conveni"
                    :value="constants.CONVENIENCE_STORE.FAMILY_MART"
                  />
                  <label for="convenience-familymart">ファミリーマート</label>
                </div>
              </div>
            </div>
            <div class="column is-4-tablet is-12-mobile">
              <div class="field">
                <div class="control">
                  <input
                    type="radio"
                    id="convenience-lawson"
                    class="radio-type-2"
                    v-model="form.conveni"
                    :value="constants.CONVENIENCE_STORE.LAWSON"
                  />
                  <label for="convenience-lawson">ローソン</label>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <ErrorField :error="f$.conveni.$errors" />
            </div>
          </div>
        </div>
      </div>

      <div class="btn-area field">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-full-mobile">
            <div class="control">
              <router-link class="button prev-btn is-medium is-fullwidth" :to="{ name: 'ConfirmInformation' }">確認画面に戻る</router-link>
            </div>
          </div>
          <div class="column is-5-tablet is-full-mobile">
            <div class="control">
              <a class="button next-btn is-medium is-fullwidth" @click="submit">申込完了</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, UnwrapRef } from "vue";
import constants from "common/src/constants";
import { PAY010Form, PAY010FormValidateRule } from "@/forms/pay010";
import useVuelidate from "@vuelidate/core";
import ErrorField from "common/src/components/ErrorField.vue";
import { postEntry } from "@/api/entry";
import axios from "axios";
import router from "@/router";
import { StepMutationTypes } from "@/store/modules/steps";
import { useStore } from "@/store";
import { ConveniMutationTypes } from "@/store/modules/conveni";
import { EntryMutationTypes } from "@/store/modules/entry";
import { ErrorsMutationTypes } from "@/store/modules/errors";

export default defineComponent({
  components: {
    ErrorField,
  },
  setup() {
    const form: UnwrapRef<PAY010Form> = reactive({ conveni: "" });

    // 全体エラーメッセージ
    const g$: string[] = reactive([]);
    // フィールドエラーを外部からセットする変数
    const $externalResults = ref({});
    // フィールドエラー設定初期化
    const f$ = useVuelidate(PAY010FormValidateRule, form, {
      $autoDirty: true,
      $externalResults,
    });

    const store = useStore();

    // 申込処理実行
    const submit = async () => {
      f$.value.$touch();
      if (f$.value.$invalid) return;

      try {
        // コンビニコードセット
        store.commit(EntryMutationTypes.SET_CONVENI_CODE, form.conveni);

        // API0230呼び出し
        const res = await postEntry(store.state.entry);

        store.commit(StepMutationTypes.STEP6, true);
        store.commit(ConveniMutationTypes.SET_CONVENI, res.data);

        router.push({ name: "RegistrationCompleted" });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 400) {
          store.commit(ErrorsMutationTypes.SET_ERRORS, err.response.data);
          router.push({ name: "ApplicationInput" });
        }
      }
    };

    return {
      form,
      constants,
      g$,
      f$,
      submit,
    };
  },
});
</script>
