import { EntryResponse } from "@/api/entry";
import { CommitOptions, Module, MutationTree, Store } from "vuex";
import { RootState } from "..";

/**
 * Vuexによる管理を行う対象
 */
export const state: EntryResponse = {
  tfMscono: "",
  tfMsconv: "",
  tfMsodid: "",
  tfMspydt: "",
  tfMsreno: "",
  receiptUrl: "",
};

/**
 * stateの状態を変更するためのMutation名定義
 * 各Storeをnamespacedにしないため、ユニークなMutation名となるようにする
 */
export enum ConveniMutationTypes {
  /**
   * プランセット処理
   */
  SET_CONVENI = "SET_CONVENI",
  /**
   * 初期化処理
   */
  RESET = "RESET_CONVENI",
}

/**
 * Mutation定義
 */
export type Mutations<S = EntryResponse> = {
  [ConveniMutationTypes.SET_CONVENI](state: S, conveniResponse: EntryResponse): void;
  [ConveniMutationTypes.RESET](state: S): void;
};

/**
 * Mutation実装
 */
export const mutations: MutationTree<EntryResponse> & Mutations = {
  [ConveniMutationTypes.SET_CONVENI](state: EntryResponse, conveniResponse: EntryResponse) {
    state.tfMscono = conveniResponse.tfMscono;
    state.tfMsconv = conveniResponse.tfMsconv;
    state.tfMsodid = conveniResponse.tfMsodid;
    state.tfMspydt = conveniResponse.tfMspydt;
    state.tfMsreno = conveniResponse.tfMsreno;
    state.receiptUrl = conveniResponse.receiptUrl;
  },
  [ConveniMutationTypes.RESET](state: EntryResponse) {
    state.tfMscono = "";
    state.tfMsconv = "";
    state.tfMsodid = "";
    state.tfMspydt = "";
    state.tfMsreno = "";
    state.receiptUrl = "";
  },
};

/**
 * ストアの型定義
 */
export type ConveniStore<S = EntryResponse> = Omit<Store<S>, "commit"> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(key: K, payload?: P, options?: CommitOptions): ReturnType<Mutations[K]>;
};

/**
 * ストア
 */
export const conveniStore: Module<EntryResponse, RootState> = {
  state,
  mutations,
};
