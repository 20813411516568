import constants from "../constants";

/**
 * 性別のコードを受け取り、対応するテキストを返します
 * @param code
 * @returns
 */
export const getGenderText = (code: string): string => {
  if (code === constants.GENDER.MAN) return "男性";
  if (code === constants.GENDER.WOMAN) return "女性";
  return "";
};

/**
 * 払込方法のコードを受け取り、対応するテキストを返します
 */
export const getHaraikomiText = (code: string): string => {
  if (code === constants.HARAIKOMI_HOUHOU.TYOKU) return "現金支払";
  if (code === constants.HARAIKOMI_HOUHOU.CONVENI) return "コンビニ決済";
  return "";
};

/**
 * 入金ステータスのコードを受け取り、対応するテキストを返します
 * @param code
 * @returns
 */
export const getNyukinStatusText = (code: string): string => {
  if (code === constants.NYUKIN_STATUS.SYONIN_NYUKINZUMI) return "承認済・入金済";
  if (code === constants.NYUKIN_STATUS.SYONIN_MINYUKIN) return "承認済・未入金";
  if (code === constants.NYUKIN_STATUS.HORYU_NYUKINZUMI) return "保留・入金済";
  if (code === constants.NYUKIN_STATUS.HORYU_MINYUKIN) return "保留・未入金";
  if (code === constants.NYUKIN_STATUS.KAIYAKU_TORIKESHI_NADO) return "解約・取消等";
  return "";
};

/**
 * ステータスのコードを受け取り、対応するテキストを返します
 * @param code
 * @returns
 */
export const getStatusText = (code: string): string => {
  if (code === constants.STATUS.KEIYAKU) return "契約";
  if (code === constants.STATUS.KOUSHIN) return "更新待ち";
  return "";
};

/**
 * ステータスのコードを受け取り、対応するテキストを返します
 * @param code
 * @returns
 */
export const getKeiyakuStatusText = (code: string): string => {
  if (code === constants.KEIYAKU_KBN.SHINKI) return "新規";
  if (code === constants.KEIYAKU_KBN.KOUSHIN) return "更新";
  return "";
};

/**
 * 所有区分のコードを受け取り、対応するテキストを返します
 * @param code
 * @returns
 */
export const getSyoyuText = (code: string): string => {
  if (code === constants.SYOYU_KBN.JYUTAKU) return "住宅";
  return "";
};

/**
 * 用途のコードを受け取り、対応するテキストを返します
 * @param code
 * @returns
 */
export const getYoutoText = (code: string): string => {
  if (code === constants.YOUTO.KAZAI) return "家財一式";
  return "";
};

/**
 * 構造のコードを受け取り、対応するテキストを返します
 * @param code
 * @returns
 */
export const getKozoText = (code: string): string => {
  if (code === constants.KOZO.MOKUZO) return "木造";
  if (code === constants.KOZO.HIMOKUZO) return "非木造";
  return "";
};

/**
 * コンビニのコードを受け取り、対応するテキストを返します
 * @param code
 * @returns
 */
export const getConveniText = (code: string): string => {
  if (code === constants.CONVENIENCE_STORE.FAMILY_MART) return "ファミリーマート";
  if (code === constants.CONVENIENCE_STORE.LAWSON) return "ローソン";
  if (code === constants.CONVENIENCE_STORE.SEVEN_ELEVEN) return "セブンイレブン";
  return "";
};
