
import { EmailAcceptRequest, postEmailAcceptRequest } from "@/api/email";
import { SCR020Form, SCR020FormValidateRule } from "@/forms/scr020";
import router from "@/router";
import { useStore } from "@/store";
import { StepMutationTypes } from "@/store/modules/steps";
import useVuelidate from "@vuelidate/core";
import { defineComponent, reactive, ref, UnwrapRef } from "vue";
import ErrorField from "common/src/components/ErrorField.vue";
import axios from "axios";
import { scrollToError } from "common/src/utils/screen";

export default defineComponent({
  components: {
    ErrorField,
  },
  setup() {
    const form: UnwrapRef<SCR020Form> = reactive({
      authNumber: "",
    });

    // フィールドエラーを外部からセットする変数
    const $externalResults = ref({});
    // フィールドエラー設定初期化
    const f$ = useVuelidate(SCR020FormValidateRule, form, {
      $externalResults,
    });

    const store = useStore();

    const confirm = async () => {
      f$.value.$clearExternalResults();
      f$.value.$touch();
      if (f$.value.$invalid) return;

      try {
        // API0220呼び出し
        const req: EmailAcceptRequest = {
          authNumber: form.authNumber,
          serialNo: store.state.entry.security.tfHnuuid,
        };
        await postEmailAcceptRequest(req);

        store.commit(StepMutationTypes.STEP2, true);

        // SCR030 管理番号入力画面へ遷移
        router.push({ name: "ControlNumberInput" });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 403) {
          $externalResults.value = { authNumber: "確認番号がまちがっています" };
          scrollToError();
        }
      }
    };

    return {
      form,
      confirm,
      f$,
    };
  },
});
