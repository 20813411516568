import { anonymousRequest } from "../utils/request";
import { AxiosResponse } from "axios";

export type DTM01P = {
  /** 代理店コード */
  tf_drdrcd: string;
  /** 代理店名称 */
  tf_drdrnm: string;
};

/**
 * API0170 代理店全情報取得 レスポンスインターフェース
 */
export type AgentResponse = {
  dtm01p: DTM01P[];
};

/**
 * API0170 代理店全情報取得
 * @returns
 */
export const getAgents = async (): Promise<AxiosResponse<AgentResponse>> => {
  return await anonymousRequest.get("/v1/agents");
};
