import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Login from "@/views/SCR010.vue";
import Confirm from "@/views/SCR020.vue";
import Control from "@/views/SCR030.vue";
import Application from "@/views/SCR040.vue";
import ConfirmInformation from "@/views/SCR050.vue";
import ConveniPayment from "@/views/PAY010.vue";
import RegistrationCompleted from "@/views/SCR060.vue";
import Forbidden from "common/src/views/error-pages/403.vue";
import MethodNotAllowed from "common/src/views/error-pages/405.vue";
import RequestTimeOut from "common/src/views/error-pages/408.vue";
import InternalServerError from "common/src/views/error-pages/500.vue";
import ServiceUnavailable from "common/src/views/error-pages/503.vue";
import NotFound from "common/src/views/error-pages/404.vue";
import { useStore } from "@/store";
import constants from "common/src/constants";
import { getServerTime } from "common/src/api/serverTime";
import { EntryMutationTypes } from "@/store/modules/entry";
import { getPlanList, PlanRequest } from "@/api/plan";
import { PlanMutationTypes } from "@/store/modules/plans";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "DisclosureStatement",
    component: Login,
    meta: { title: "お申込みTOP", englishTitle: "TOP", screenId: "SCR010", steps: 1 },
  },
  {
    path: "/confirm",
    name: "ConfirmEmailAddress",
    component: Confirm,
    meta: { title: "確認メール送信結果", englishTitle: "Confirm email address", screenId: "SCR020", steps: 2 },
  },
  {
    path: "/control",
    name: "ControlNumberInput",
    component: Control,
    meta: { title: "管理番号入力", englishTitle: "Control Number Input", screenId: "SCR030", steps: 3 },
  },
  {
    path: "/application",
    name: "ApplicationInput",
    component: Application,
    meta: { title: "申込内容入力", englishTitle: "Application Input", screenId: "SCR040", steps: 4 },
  },
  {
    path: "/application/confirm",
    name: "ConfirmInformation",
    component: ConfirmInformation,
    meta: { title: "申込内容確認", englishTitle: "Confirm Information", screenId: "SCR050", steps: 5 },
  },
  {
    path: "/application/payment",
    name: "ConveniPayment",
    component: ConveniPayment,
    meta: { title: "コンビニ決済", englishTitle: "Convenience Settlement", screenId: "PAY010", steps: 6 },
  },
  {
    path: "/application/complete",
    name: "RegistrationCompleted",
    component: RegistrationCompleted,
    meta: { title: "申込内容確認", englishTitle: "Registration Completed", screenId: "SCR060", steps: 7 },
  },
  {
    path: "/403",
    name: "403",
    component: Forbidden,
    meta: { errorPage: true },
  },
  {
    path: "/405",
    name: "405",
    component: MethodNotAllowed,
    meta: { errorPage: true },
  },
  {
    path: "/408",
    name: "408",
    component: RequestTimeOut,
    meta: { errorPage: true },
  },
  {
    path: "/500",
    name: "500",
    component: InternalServerError,
    meta: { errorPage: true },
  },
  {
    path: "/503",
    name: "503",
    component: ServiceUnavailable,
    meta: { errorPage: true },
  },
  {
    // 404ページ設定
    path: "/:pathMatch(.*)*",
    name: "404",
    component: NotFound,
    meta: { errorPage: true },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// ステップ順に進まなかった場合の前画面遷移処理
router.beforeEach((to, from, next) => {
  const store = useStore();

  // 画面遷移時に現在日が取得できてなかった場合に取得する
  if (store.state.entry.msk01p.tfMsmsdtY === 0) {
    // 現在日・プランリストを取得してストアにセット
    getServerTime().then((res) => {
      store.commit(EntryMutationTypes.SET_ENTRY_DATE, res.data.serverDate);
      const planReq: PlanRequest = {
        entryDate: res.data.serverDate,
        productCode: constants.SHOUHIN_CODE.SANKO_LIFE_GUARD,
      };
      getPlanList(planReq).then((res) => store.commit(PlanMutationTypes.SET_PLAN_LIST, res.data.list));
    });
  }

  if (to.meta.steps && (to.meta.steps as number) > 1) {
    const steps = to.meta.steps as number;

    switch (steps) {
      case 2:
        if (!store.state.steps.step1) {
          next({ name: "DisclosureStatement" });
          return;
        }
        break;
      case 3:
        if (!store.state.steps.step2) {
          next({ name: "DisclosureStatement" });
          return;
        }
        break;
      case 4:
        if (!store.state.steps.step2) {
          next({ name: "DisclosureStatement" });
          return;
        }
        break;
      case 5:
        if (!store.state.steps.step4) {
          next({ name: "ApplicationInput" });
          return;
        }
        break;
      case 6:
        if (!store.state.steps.step4 || store.state.entry.msk01p.tfMshkhh !== constants.HARAIKOMI_HOUHOU.CONVENI) {
          next({ name: "ApplicationInput" });
          return;
        }
        break;
      case 7:
        // 申込完了時は、直入金の場合ステップ5のフラグ、コンビニ決済の場合ステップ6のフラグをtrueとする
        if (
          (!store.state.steps.step5 && !store.state.steps.step6) ||
          (store.state.entry.msk01p.tfMshkhh === constants.HARAIKOMI_HOUHOU.TYOKU && !store.state.steps.step5) ||
          (store.state.entry.msk01p.tfMshkhh === constants.HARAIKOMI_HOUHOU.CONVENI && !store.state.steps.step6)
        ) {
          next({ name: "ConfirmInformation" });
          return;
        }
        break;
      default:
        break;
    }
  }
  next();
});

router.afterEach((to) => {
  window.scrollTo({ top: 0 });

  if (to.meta.title) {
    document.title = `${to.meta.title} - ${process.env.VUE_APP_BASE_TITLE}`;
  } else {
    document.title = process.env.VUE_APP_BASE_TITLE;
  }
});

export default router;
