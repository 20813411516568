/**
 * 申込の進行状況を管理するストア
 */

import { CommitOptions, Module, MutationTree, Store } from "vuex";
import { RootState } from "..";

/**
 * Vuexによる管理を行う対象
 */
export type StepState = {
  step1: boolean;
  step2: boolean;
  step4: boolean;
  step5: boolean;
  step6: boolean;
};

/**
 * Vuexによる管理を行う対象
 */
export const state: StepState = {
  step1: false,
  step2: false,
  step4: false,
  step5: false,
  step6: false,
};

/**
 * stateの状態を変更するためのMutation名定義
 * 各storeをnamespacedにしないため、ユニークなMutation名となるようにする
 */
export enum StepMutationTypes {
  STEP1 = "step1",
  STEP2 = "step2",
  STEP4 = "step4",
  STEP5 = "step5",
  STEP6 = "step6",
  RESET = "RESET_STEP",
}

/**
 * Mutation定義
 */
export type Mutations<S = StepState> = {
  [StepMutationTypes.STEP1](state: S, status: boolean): void;
  [StepMutationTypes.STEP2](state: S, status: boolean): void;
  [StepMutationTypes.STEP4](state: S, status: boolean): void;
  [StepMutationTypes.STEP5](state: S, status: boolean): void;
  [StepMutationTypes.STEP6](state: S, status: boolean): void;
  [StepMutationTypes.RESET](state: S): void;
};

/**
 * Mutation実装
 */
export const mutations: MutationTree<StepState> & Mutations = {
  [StepMutationTypes.STEP1](state: StepState, status: boolean) {
    state.step1 = status;
  },
  [StepMutationTypes.STEP2](state: StepState, status: boolean) {
    state.step2 = status;
  },
  [StepMutationTypes.STEP4](state: StepState, status: boolean) {
    state.step4 = status;
  },
  [StepMutationTypes.STEP5](state: StepState, status: boolean) {
    state.step5 = status;
  },
  [StepMutationTypes.STEP6](state: StepState, status: boolean) {
    state.step6 = status;
  },
  [StepMutationTypes.RESET](state: StepState) {
    state.step1 = false;
    state.step2 = false;
    state.step4 = false;
    state.step5 = false;
    state.step6 = false;
  },
};

/**
 * ストアの型定義
 */
export type StepStore<S = StepState> = Omit<Store<S>, "commit"> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(key: K, payload?: P, options?: CommitOptions): ReturnType<Mutations[K]>;
};

/**
 * ストア
 */
export const stepStore: Module<StepState, RootState> = {
  state,
  mutations,
};
