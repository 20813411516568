
import { useStore } from "@/store";
import { computed, defineComponent, reactive, Ref, ref, UnwrapRef } from "vue";
import { getGenderText, getHaraikomiText } from "common/src/utils/codes";
import { commaSplit } from "common/src/utils/number";
import { SCR050Form, SCR050FormValidateRule } from "@/forms/scr050";
import constants from "common/src/constants";
import ErrorField from "common/src/components/ErrorField.vue";
import HyphenParagraph from "common/src/components/HyphenParagraph.vue";
import SubInsuredConfirm from "@/components/SubInsuredConfirm.vue";
import useVuelidate from "@vuelidate/core";
import { postEntry } from "@/api/entry";
import router from "@/router";
import axios from "axios";
import { StepMutationTypes } from "@/store/modules/steps";
import { getSyoyuText, getYoutoText, getKozoText } from "common/src/utils/codes";
import { ErrorsMutationTypes } from "@/store/modules/errors";

export default defineComponent({
  components: {
    ErrorField,
    HyphenParagraph,
    SubInsuredConfirm,
  },
  setup() {
    const form: UnwrapRef<SCR050Form> = reactive({
      contractor: false,
      location: false,
      insured: false,
      compensation: false,
      contractDetails: false,
    });
    const store = useStore();
    const entry = store.state.entry.msk01p;
    const mailAddress = store.state.mail.mailAddress;
    const mainInsured = store.state.entry.msk02p[0];
    const subInsureds = store.state.entry.msk02p.slice(1, store.state.entry.msk02p.length);
    const location = store.state.entry.location;
    const selectedPlan = store.state.plans.filter((p) => p.tf_hrplan === entry.tfMshkpl)[0];
    const isConveni = store.state.entry.msk01p.tfMshkhh === constants.HARAIKOMI_HOUHOU.CONVENI;
    // 他保険種類管理配列
    const thMsthsrArr: Ref<string[]> = ref([]);
    thMsthsrArr.value.push(...(store.state.entry.msk01p.tfMsthsr.split("/") ?? []));

    // 全体エラーメッセージ
    const g$: string[] = reactive([]);

    // フィールドエラーを外部からセットする変数
    const $externalResults = ref({});
    // フィールドエラー設定初期化
    const f$ = useVuelidate(SCR050FormValidateRule, form, {
      $externalResults,
    });

    // 確認事項のエラーを１つだけ表示
    const intentionError = computed(() => {
      if (
        f$.value.contractor.$error ||
        f$.value.location.$error ||
        f$.value.insured.$error ||
        f$.value.compensation.$error ||
        f$.value.contractDetails.$error
      ) {
        return [
          ...f$.value.contractor.$errors,
          ...f$.value.location.$errors,
          ...f$.value.insured.$errors,
          ...f$.value.compensation.$errors,
          ...f$.value.contractDetails.$errors,
        ][0].$message;
      }
      return "";
    });

    // 申込処理実行
    const submit = async () => {
      f$.value.$touch();
      if (f$.value.$invalid) return;

      // コンビニ決済時は決済画面にて申込処理を呼び出す
      if (store.state.entry.msk01p.tfMshkhh === constants.HARAIKOMI_HOUHOU.CONVENI) {
        router.push({ name: "ConveniPayment" });
        return;
      }

      try {
        // API0230呼び出し
        await postEntry(store.state.entry);

        store.commit(StepMutationTypes.STEP5, true);

        router.push({ name: "RegistrationCompleted" });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 400) {
          store.commit(ErrorsMutationTypes.SET_ERRORS, err.response.data);
          router.push({ name: "ApplicationInput" });
        }
      }
    };

    return {
      form,
      entry,
      mailAddress,
      mainInsured,
      subInsureds,
      location,
      selectedPlan,
      getGenderText,
      getHaraikomiText,
      constants,
      commaSplit,
      intentionError,
      f$,
      g$,
      submit,
      getSyoyuText,
      getYoutoText,
      getKozoText,
      isConveni,
      thMsthsrArr,
    };
  },
});
