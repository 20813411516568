import { helpers, maxLength, sameAs } from "@vuelidate/validators";
import { computed } from "vue";
import { validMail } from "common/src/utils/validate";
import constants from "common/src/constants";

export type SCR010Form = {
  /** 重要事項説明 */
  tf_msjyjk: boolean;
  /** 対象確認 */
  target_verify: boolean;
  /** 地震付帯確認 */
  earthquake_verify: boolean;
  /** 通知義務確認 */
  notification_verify: boolean;
  /** 当社他契約有無(仮) */
  another_contract: string | null;
  /** 契約者メールアドレス */
  tf_mskyma: string | null;
};

export const SCR010FormValidateRule = computed(() => ({
  tf_msjyjk: {
    sameAs: helpers.withMessage("重要事項説明に同意いただけない場合、契約を行うことが出来ません", sameAs(true)),
  },
  target_verify: {
    sameAs: helpers.withMessage("意向確認に確認頂けない場合、契約を行うことが出来ません", sameAs(true)),
  },
  earthquake_verify: {
    sameAs: helpers.withMessage("意向確認に確認頂けない場合、契約を行うことが出来ません", sameAs(true)),
  },
  notification_verify: {
    sameAs: helpers.withMessage("意向確認に確認頂けない場合、契約を行うことが出来ません", sameAs(true)),
  },
  another_contract: {
    required: helpers.withMessage("加入状況を選択してください", (val) => val !== null),
    ari: helpers.withMessage(
      "「SANKOライフガード」または「SANKOライフ保険」は重複してのご加入ができません。代理店　山晃住宅（エイブル）までお問合せください。",
      (val) => val !== constants.ARI.ARI
    ),
  },
  tf_mskyma: {
    mailAdress: helpers.withMessage("正しいメールアドレスを入力してください", validMail),
    maxLength: helpers.withMessage(({ $params }) => `メールアドレスは${($params as { max: number }).max}文字で入力してください`, maxLength(60)),
  },
}));
