
import { defineComponent } from "vue";
import { getGenderText } from "common/src/utils/codes";
import HyphenParagraph from "common/src/components/HyphenParagraph.vue";

export default defineComponent({
  components: {
    HyphenParagraph,
  },
  props: {
    /** 被保険者番号 */
    insuredNumber: {
      type: Number,
      required: true,
    },
    /** 被保険者カナ(姓) */
    tfMhhkskSei: {
      type: String,
      required: true,
    },
    /** 被保険者カナ(名) */
    tfMhhkskMei: {
      type: String,
      required: true,
    },
    /** 被保険者漢字(姓) */
    tfMhhksjSei: {
      type: String,
      required: true,
    },
    /** 被保険者漢字(名) */
    tfMhhksjMei: {
      type: String,
      required: true,
    },
    /** 被保険者生年月日 年 */
    tfMhhkbtY: {
      type: Number,
      required: false,
    },
    /** 被保険者生年月日 月 */
    tfMhhkbtM: {
      type: Number,
      required: false,
    },
    /** 被保険者生年月日 日 */
    tfMhhkbtD: {
      type: Number,
      required: false,
    },
    /** 被保険者性別 */
    tfMhhksx: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {
      props,
      getGenderText,
    };
  },
});
