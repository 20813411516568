import { Plan } from "@/api/plan";
import { CommitOptions, Module, MutationTree, Store } from "vuex";
import { RootState } from "..";

/**
 * Vuexによる管理を行う対象
 */
export const state: Plan[] = [];

/**
 * stateの状態を変更するためのMutation名定義
 * 各Storeをnamespacedにしないため、ユニークなMutation名となるようにする
 */
export enum PlanMutationTypes {
  /**
   * プランセット処理
   */
  SET_PLAN_LIST = "SET_PLAN_LIST",
}

/**
 * Mutation定義
 */
export type Mutations<S = Plan[]> = {
  [PlanMutationTypes.SET_PLAN_LIST](state: S, plans: Plan[]): void;
};

/**
 * Mutation実装
 */
export const mutations: MutationTree<Plan[]> & Mutations = {
  [PlanMutationTypes.SET_PLAN_LIST](state: Plan[], plans: Plan[]) {
    state.splice(0, state.length);
    state.push(...plans);
  },
};

/**
 * ストアの型定義
 */
export type PlanStore<S = Plan[]> = Omit<Store<S>, "commit"> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(key: K, payload: P, options?: CommitOptions): ReturnType<Mutations[K]>;
};

/**
 * ストア
 */
export const planStore: Module<Plan[], RootState> = {
  state,
  mutations,
};
