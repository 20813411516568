import { anonymousRequest } from "common/src/utils/request";

/**
 * API0210 本人確認リクエスト リクエストインターフェース
 */
export type EmailRequest = {
  mailAddress: string;
};

/**
 *API0210 本人確認リクエスト レスポンスインターフェース
 */
export type EmailResponse = {
  serialNo: string;
};

/**
 * API0220 本人確認 リクエストインターフェース
 */
export type EmailAcceptRequest = {
  /** 本人確認番号 */
  authNumber: string;
  /** シリアル番号 */
  serialNo: string;
};

/**
 * API0210 本人確認リクエスト
 * @param email
 * @returns
 */
export const postEmailRequest = async (email: EmailRequest): Promise<EmailResponse> => {
  const res = await anonymousRequest.post("/v1/email/request", email);
  return res.data as EmailResponse;
};

/**
 * API0220 本人確認
 * @param accept
 */
export const postEmailAcceptRequest = async (accept: EmailAcceptRequest): Promise<void> => {
  await anonymousRequest.post("/v1/email/accept", accept);
};
