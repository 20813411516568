<template>
  <div class="errorpage">
    <h1>403.</h1>
    <h2>Forbidden</h2>
    <div class="description">
      <p>長時間操作していなかったためセッションが切れたかこのページにアクセスする権限がないため、ご覧になることができません。</p>
      <p>URLが正しく入力されているかご確認ください。</p>
    </div>
  </div>
</template>

<style scoped>
.errorpage {
  margin: 2em;
  color: #3c4858;
}
.errorpage h1 {
  font-size: 7em;
  margin-bottom: 20px;
  font-weight: 700;
}
.errorpage h2 {
  font-size: 1.5em;
  font-weight: 700;
}
.errorpage .description {
  font-size: 0.85em;
  margin-top: 4em;
}
.errorpage .description p {
  margin: 0;
}
</style>
