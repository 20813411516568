
import useVuelidate from "@vuelidate/core";
import ErrorField from "common/src/components/ErrorField.vue";
import SelectDate from "common/src/components/SelectDate.vue";
import { helpers, maxLength, or, requiredIf } from "@vuelidate/validators";
import { computed, defineComponent } from "vue";
import constants from "common/src/constants";
import { validZenkakuKanaForName, createErrorObject } from "common/src/utils/validate";

export default defineComponent({
  components: {
    ErrorField,
    SelectDate,
  },
  props: {
    /** 誕生日選択可能年の基準となる年 */
    baseYear: {
      type: Number,
      required: true,
    },
    insuredNumber: {
      type: Number,
      required: true,
    },
    /** 被保険者を設定するかどうか */
    enabled: {
      type: Boolean,
      required: true,
    },
    /** 被保険者名 カナ 姓 */
    tfMhhkskSei: {
      type: String,
      required: true,
    },
    /** 被保険者名 カナ 名 */
    tfMhhkskMei: {
      type: String,
      required: true,
    },
    /** 被保険者名 漢字 姓 */
    tfMhhksjSei: {
      type: String,
      required: true,
    },
    /** 被保険者名 漢字 名 */
    tfMhhksjMei: {
      type: String,
      required: true,
    },
    /** 被保険者生年月日 年 */
    tfMhhkbtY: {
      type: Number,
      required: false,
    },
    /** 被保険者生年月日 月 */
    tfMhhkbtM: {
      type: Number,
      required: false,
    },
    /** 被保険者生年月日 日 */
    tfMhhkbtD: {
      type: Number,
      required: false,
    },
    /** 被保険者性別 */
    tfMhhksx: {
      type: String,
      required: true,
    },
    /** 各被保険者欄の手動チェックを表示にするためのプロップ */
    isInsuredNumberCheckContentsVisible: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, context) {
    const tfMhhkskSeiData = computed({
      get: () => props.tfMhhkskSei,
      set: (v: string) => context.emit("update:tfMhhkskSei", v),
    });
    const tfMhhkskMeiData = computed({
      get: () => props.tfMhhkskMei,
      set: (v: string) => context.emit("update:tfMhhkskMei", v),
    });
    const tfMhhksjSeiData = computed({
      get: () => props.tfMhhksjSei,
      set: (v: string) => context.emit("update:tfMhhksjSei", v),
    });
    const tfMhhksjMeiData = computed({
      get: () => props.tfMhhksjMei,
      set: (v: string) => context.emit("update:tfMhhksjMei", v),
    });
    const tfMhhkbtYData = computed({
      get: () => props.tfMhhkbtY,
      set: (v: number | undefined) => context.emit("update:tfMhhkbtY", v),
    });
    const tfMhhkbtMData = computed({
      get: () => props.tfMhhkbtM,
      set: (v: number | undefined) => context.emit("update:tfMhhkbtM", v),
    });
    const tfMhhkbtDData = computed({
      get: () => props.tfMhhkbtD,
      set: (v: number | undefined) => context.emit("update:tfMhhkbtD", v),
    });
    const tfMhhksxData = computed({
      get: () => props.tfMhhksx,
      set: (v: string) => context.emit("update:tfMhhksx", v),
    });
    const enabledData = computed({
      get: () => props.enabled,
      set: (v: boolean) => context.emit("update:enabled", v),
    });

    const validator = computed(() => ({
      tfMhhksjSei: {
        required: helpers.withMessage("氏名 漢字 姓を入力してください", requiredIf(props.enabled)),
        max: helpers.withMessage(
          "氏名 漢字 姓は30文字以内で入力してください",
          or(() => !props.enabled, maxLength(30))
        ),
      },
      tfMhhksjMei: {
        required: helpers.withMessage("氏名 漢字 名を入力してください", requiredIf(props.enabled)),
        max: helpers.withMessage(
          "氏名 漢字 名は30文字以内で入力してください",
          or(() => !props.enabled, maxLength(30))
        ),
      },
      tfMhhkskSei: {
        required: helpers.withMessage("氏名 カナ 姓を入力してください", requiredIf(props.enabled)),
        max: helpers.withMessage(
          "氏名 カナ 姓は30文字以内で入力してください",
          or(() => !props.enabled, maxLength(30))
        ),
        zenkakuKana: helpers.withMessage("氏名 カナ 姓は全角カナ・数字・スペース・英字・記号で入力してください", validZenkakuKanaForName),
      },
      tfMhhkskMei: {
        required: helpers.withMessage("氏名 カナ 名を入力してください", requiredIf(props.enabled)),
        max: helpers.withMessage(
          "氏名 カナ 名は30文字以内で入力してください",
          or(() => !props.enabled, maxLength(30))
        ),
        zenkakuKana: helpers.withMessage("氏名 カナ 名は全角カナ・数字・スペース・英字・記号で入力してください", validZenkakuKanaForName),
      },
      tfMhhkbtY: {
        required: helpers.withMessage("生年月日 年を選択してください", requiredIf(props.enabled)),
      },
      tfMhhkbtM: {
        required: helpers.withMessage("生年月日 月を選択してください", requiredIf(props.enabled)),
      },
      tfMhhkbtD: {
        required: helpers.withMessage("生年月日 日を選択してください", requiredIf(props.enabled)),
      },
      tfMhhksx: {
        required: helpers.withMessage("性別を選択してください", requiredIf(props.enabled)),
      },
    }));

    // フィールドエラー設定初期化
    const f$ = useVuelidate(validator, props, {
      $autoDirty: true,
    });

    // 生年月日に関するチェック
    const validBirthDate = () => {
      f$.value.tfMhhkbtY.$reset();
      f$.value.tfMhhkbtY.$touch();
      if (props.tfMhhkbtY !== undefined && props.tfMhhkbtM !== undefined && props.tfMhhkbtD !== undefined) {
        const nowDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        const birthDate = new Date(props.tfMhhkbtY, props.tfMhhkbtM - 1, props.tfMhhkbtD);
        if (nowDate < birthDate) {
          f$.value.tfMhhkbtY.$errors.push(createErrorObject("生年月日に未来の日付が選択されています"));
        }
      }
    };

    return {
      props,
      enabledData,
      tfMhhkskSeiData,
      tfMhhkskMeiData,
      tfMhhksjSeiData,
      tfMhhksjMeiData,
      tfMhhkbtYData,
      tfMhhkbtMData,
      tfMhhkbtDData,
      tfMhhksxData,
      f$,
      validBirthDate,
      constants,
    };
  },
});
