<template>
  <div v-if="isLock || isLoading" class="loader-wrap">
    <div class="loader"></div>
  </div>
</template>

<script lang="ts">
import { setLoading } from "../utils/request";
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const isLoading = ref(false);
    const isLock = ref(false);
    const loadingLock = () => (isLock.value = true);
    const loadingUnlock = () => (isLock.value = false);

    setLoading(isLoading);

    return {
      isLoading,
      isLock,
      loadingLock,
      loadingUnlock,
    };
  },
});
</script>

<style scoped>
.loader-wrap {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 1;
  opacity: 0.5;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1.5s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
