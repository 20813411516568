/**
 * 指定したstart、endの数値配列を返します
 * @param start 配列の最初の値
 * @param end 配列の最後の値
 * @param diff 要素間の差 default 1
 * @returns
 */
export const range: (start: number, end: number, diff?: number) => number[] = (start, end, diff) => {
  diff = diff ?? 1;

  const ret: number[] = [];
  for (let i = start; start < end ? i <= end : i >= end; i += diff) {
    ret.push(i);
  }

  return ret;
};

/**
 * 渡された数値を3桁カンマ区切りにして返します
 * @param target 整数
 * @returns
 */
export const commaSplit: (target: number) => string = (target) => target.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,");
