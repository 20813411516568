
import { defineComponent, reactive, ref, UnwrapRef } from "vue";
import constants from "common/src/constants";
import { PAY010Form, PAY010FormValidateRule } from "@/forms/pay010";
import useVuelidate from "@vuelidate/core";
import ErrorField from "common/src/components/ErrorField.vue";
import { postEntry } from "@/api/entry";
import axios from "axios";
import router from "@/router";
import { StepMutationTypes } from "@/store/modules/steps";
import { useStore } from "@/store";
import { ConveniMutationTypes } from "@/store/modules/conveni";
import { EntryMutationTypes } from "@/store/modules/entry";
import { ErrorsMutationTypes } from "@/store/modules/errors";

export default defineComponent({
  components: {
    ErrorField,
  },
  setup() {
    const form: UnwrapRef<PAY010Form> = reactive({ conveni: "" });

    // 全体エラーメッセージ
    const g$: string[] = reactive([]);
    // フィールドエラーを外部からセットする変数
    const $externalResults = ref({});
    // フィールドエラー設定初期化
    const f$ = useVuelidate(PAY010FormValidateRule, form, {
      $autoDirty: true,
      $externalResults,
    });

    const store = useStore();

    // 申込処理実行
    const submit = async () => {
      f$.value.$touch();
      if (f$.value.$invalid) return;

      try {
        // コンビニコードセット
        store.commit(EntryMutationTypes.SET_CONVENI_CODE, form.conveni);

        // API0230呼び出し
        const res = await postEntry(store.state.entry);

        store.commit(StepMutationTypes.STEP6, true);
        store.commit(ConveniMutationTypes.SET_CONVENI, res.data);

        router.push({ name: "RegistrationCompleted" });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 400) {
          store.commit(ErrorsMutationTypes.SET_ERRORS, err.response.data);
          router.push({ name: "ApplicationInput" });
        }
      }
    };

    return {
      form,
      constants,
      g$,
      f$,
      submit,
    };
  },
});
