import { CommitOptions, Module, MutationTree, Store } from "vuex";
import { RootState } from "..";

export type AnotherState = {
  hasAnother: string | null;
};

/**
 * Vuexによる管理を行う対象
 */
export const state: AnotherState = { hasAnother: null };

/**
 * stateの状態を変更するためのMutation名定義
 * 各Storeをnamespacedにしないため、ユニークなMutation名となるようにする
 */
export enum AnotherMutationTypes {
  /**
   * 他契約有無セット処理
   */
  SET_ANOTHER = "SET_ANOTHER",
  /**
   * 初期化処理
   */
  RESET = "RESET_ANOTHER",
}

/**
 * Mutation定義
 */
export type Mutations<S = AnotherState> = {
  [AnotherMutationTypes.SET_ANOTHER](state: S, hasAnother: string | null): void;
  [AnotherMutationTypes.RESET](state: S): void;
};

/**
 * Mutation実装
 */
export const mutations: MutationTree<AnotherState> & Mutations = {
  [AnotherMutationTypes.SET_ANOTHER](state: AnotherState, hasAnother: string | null) {
    state.hasAnother = hasAnother;
  },
  [AnotherMutationTypes.RESET](state: AnotherState) {
    state.hasAnother = null;
  },
};

/**
 * ストアの型定義
 */
export type AnotherStore<S = AnotherState> = Omit<Store<S>, "commit"> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(key: K, payload?: P, options?: CommitOptions): ReturnType<Mutations[K]>;
};

/**
 * ストア
 */
export const anotherStore: Module<AnotherState, RootState> = {
  state,
  mutations,
};
