import { anonymousRequest } from "../utils/request";
import { AxiosResponse } from "axios";

/**
 * API0140 現在日時取得 レスポンスインターフェース
 */
export type ServerTimeResponse = {
  /** 日付 */
  serverDate: string;
  /** 時刻 */
  serverTime: string;
};

/**
 * API0140 現在日時取得 API呼び出し
 * @returns 現在日時
 */
export const getServerTime = async (): Promise<AxiosResponse<ServerTimeResponse>> => {
  return await anonymousRequest.get<ServerTimeResponse>("/v1/server-time");
};
