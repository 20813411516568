
import { setLoading } from "../utils/request";
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const isLoading = ref(false);
    const isLock = ref(false);
    const loadingLock = () => (isLock.value = true);
    const loadingUnlock = () => (isLock.value = false);

    setLoading(isLoading);

    return {
      isLoading,
      isLock,
      loadingLock,
      loadingUnlock,
    };
  },
});
