<template>
  <div class="insured-item">
    <label class="has-text-weight-bold has-text-dark border-contents-title">被保険者{{ props.insuredNumber }}</label>
    <div v-show="isInsuredNumberCheckContentsVisible">
      <input type="checkbox" :id="`enable-insured${props.insuredNumber}`" class="check-type-1 enable-check" v-model="enabledData" />
      <label :for="`enable-insured${props.insuredNumber}`" class="has-text-weight-bold has-text-dark border-contents-title-right">
        被保険者{{ props.insuredNumber }}を設定する
      </label>
    </div>
    <div class="insured6-info border-contents">
      <label class="label">
        氏名 漢字
        <span class="text-info has-text-danger-dark">※外国人のお客様の場合は、在留カードの表記通りにご入力ください</span>
      </label>
      <div class="columns">
        <div class="column is-half-tablet is-full-mobile">
          <div class="field">
            <label class="label">姓<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
            <div class="control">
              <input class="input" type="text" v-model="tfMhhksjSeiData" />
            </div>
          </div>
          <ErrorField :error="f$.tfMhhksjSei.$errors" />
        </div>
        <div class="column is-half-tablet is-full-mobile">
          <div class="field">
            <label class="label">名<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
            <div class="control">
              <input class="input" type="text" v-model="tfMhhksjMeiData" />
            </div>
          </div>
          <ErrorField :error="f$.tfMhhksjMei.$errors" />
        </div>
      </div>

      <label class="label">氏名 カナ</label>
      <div class="columns">
        <div class="column is-half-tablet is-full-mobile">
          <div class="field">
            <label class="label">姓<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
            <div class="control">
              <input class="input" type="text" v-model="tfMhhkskSeiData" />
            </div>
          </div>
          <ErrorField :error="f$.tfMhhkskSei.$errors" />
        </div>
        <div class="column is-half-tablet is-full-mobile">
          <div class="field">
            <label class="label">名<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
            <div class="control">
              <input class="input" type="text" v-model="tfMhhkskMeiData" />
            </div>
          </div>
          <ErrorField :error="f$.tfMhhkskMei.$errors" />
        </div>
      </div>

      <label class="label">生年月日<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
      <div class="columns">
        <SelectDate
          :generalColumnClass="'is-3-tablet is-full-mobile pr-0'"
          :baseYear="props.baseYear"
          :yearSelectableUpperLimit="0"
          :yearSelectableLowerLimit="-100"
          v-model:yearValue="tfMhhkbtYData"
          v-model:monthValue="tfMhhkbtMData"
          v-model:dateValue="tfMhhkbtDData"
        />
      </div>
      <ErrorField :error="f$.tfMhhkbtY.$errors" />
      <ErrorField :error="f$.tfMhhkbtM.$errors" />
      <ErrorField :error="f$.tfMhhkbtD.$errors" />

      <label class="label">性別<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
      <div class="columns is-flex">
        <div class="column is-3-tablet is-4-mobile">
          <div class="field">
            <div class="control">
              <input
                type="radio"
                :id="`insured${props.insuredNumber}-gender-male`"
                class="radio-type-2"
                v-model="tfMhhksxData"
                :value="constants.GENDER.MAN"
              />
              <label :for="`insured${props.insuredNumber}-gender-male`">男性</label>
            </div>
          </div>
        </div>
        <div class="column is-3-tablet is-4-mobile">
          <div class="field">
            <div class="control">
              <input
                type="radio"
                :id="`insured${props.insuredNumber}-gender-female`"
                class="radio-type-2"
                v-model="tfMhhksxData"
                :value="constants.GENDER.WOMAN"
              />
              <label :for="`insured${props.insuredNumber}-gender-female`">女性</label>
            </div>
          </div>
        </div>
      </div>
      <ErrorField :error="f$.tfMhhksx.$errors" />
    </div>
  </div>
</template>

<script lang="ts">
import useVuelidate from "@vuelidate/core";
import ErrorField from "common/src/components/ErrorField.vue";
import SelectDate from "common/src/components/SelectDate.vue";
import { helpers, maxLength, or, requiredIf } from "@vuelidate/validators";
import { computed, defineComponent } from "vue";
import constants from "common/src/constants";
import { validZenkakuKanaForName, createErrorObject } from "common/src/utils/validate";

export default defineComponent({
  components: {
    ErrorField,
    SelectDate,
  },
  props: {
    /** 誕生日選択可能年の基準となる年 */
    baseYear: {
      type: Number,
      required: true,
    },
    insuredNumber: {
      type: Number,
      required: true,
    },
    /** 被保険者を設定するかどうか */
    enabled: {
      type: Boolean,
      required: true,
    },
    /** 被保険者名 カナ 姓 */
    tfMhhkskSei: {
      type: String,
      required: true,
    },
    /** 被保険者名 カナ 名 */
    tfMhhkskMei: {
      type: String,
      required: true,
    },
    /** 被保険者名 漢字 姓 */
    tfMhhksjSei: {
      type: String,
      required: true,
    },
    /** 被保険者名 漢字 名 */
    tfMhhksjMei: {
      type: String,
      required: true,
    },
    /** 被保険者生年月日 年 */
    tfMhhkbtY: {
      type: Number,
      required: false,
    },
    /** 被保険者生年月日 月 */
    tfMhhkbtM: {
      type: Number,
      required: false,
    },
    /** 被保険者生年月日 日 */
    tfMhhkbtD: {
      type: Number,
      required: false,
    },
    /** 被保険者性別 */
    tfMhhksx: {
      type: String,
      required: true,
    },
    /** 各被保険者欄の手動チェックを表示にするためのプロップ */
    isInsuredNumberCheckContentsVisible: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, context) {
    const tfMhhkskSeiData = computed({
      get: () => props.tfMhhkskSei,
      set: (v: string) => context.emit("update:tfMhhkskSei", v),
    });
    const tfMhhkskMeiData = computed({
      get: () => props.tfMhhkskMei,
      set: (v: string) => context.emit("update:tfMhhkskMei", v),
    });
    const tfMhhksjSeiData = computed({
      get: () => props.tfMhhksjSei,
      set: (v: string) => context.emit("update:tfMhhksjSei", v),
    });
    const tfMhhksjMeiData = computed({
      get: () => props.tfMhhksjMei,
      set: (v: string) => context.emit("update:tfMhhksjMei", v),
    });
    const tfMhhkbtYData = computed({
      get: () => props.tfMhhkbtY,
      set: (v: number | undefined) => context.emit("update:tfMhhkbtY", v),
    });
    const tfMhhkbtMData = computed({
      get: () => props.tfMhhkbtM,
      set: (v: number | undefined) => context.emit("update:tfMhhkbtM", v),
    });
    const tfMhhkbtDData = computed({
      get: () => props.tfMhhkbtD,
      set: (v: number | undefined) => context.emit("update:tfMhhkbtD", v),
    });
    const tfMhhksxData = computed({
      get: () => props.tfMhhksx,
      set: (v: string) => context.emit("update:tfMhhksx", v),
    });
    const enabledData = computed({
      get: () => props.enabled,
      set: (v: boolean) => context.emit("update:enabled", v),
    });

    const validator = computed(() => ({
      tfMhhksjSei: {
        required: helpers.withMessage("氏名 漢字 姓を入力してください", requiredIf(props.enabled)),
        max: helpers.withMessage(
          "氏名 漢字 姓は30文字以内で入力してください",
          or(() => !props.enabled, maxLength(30))
        ),
      },
      tfMhhksjMei: {
        required: helpers.withMessage("氏名 漢字 名を入力してください", requiredIf(props.enabled)),
        max: helpers.withMessage(
          "氏名 漢字 名は30文字以内で入力してください",
          or(() => !props.enabled, maxLength(30))
        ),
      },
      tfMhhkskSei: {
        required: helpers.withMessage("氏名 カナ 姓を入力してください", requiredIf(props.enabled)),
        max: helpers.withMessage(
          "氏名 カナ 姓は30文字以内で入力してください",
          or(() => !props.enabled, maxLength(30))
        ),
        zenkakuKana: helpers.withMessage("氏名 カナ 姓は全角カナ・数字・スペース・英字・記号で入力してください", validZenkakuKanaForName),
      },
      tfMhhkskMei: {
        required: helpers.withMessage("氏名 カナ 名を入力してください", requiredIf(props.enabled)),
        max: helpers.withMessage(
          "氏名 カナ 名は30文字以内で入力してください",
          or(() => !props.enabled, maxLength(30))
        ),
        zenkakuKana: helpers.withMessage("氏名 カナ 名は全角カナ・数字・スペース・英字・記号で入力してください", validZenkakuKanaForName),
      },
      tfMhhkbtY: {
        required: helpers.withMessage("生年月日 年を選択してください", requiredIf(props.enabled)),
      },
      tfMhhkbtM: {
        required: helpers.withMessage("生年月日 月を選択してください", requiredIf(props.enabled)),
      },
      tfMhhkbtD: {
        required: helpers.withMessage("生年月日 日を選択してください", requiredIf(props.enabled)),
      },
      tfMhhksx: {
        required: helpers.withMessage("性別を選択してください", requiredIf(props.enabled)),
      },
    }));

    // フィールドエラー設定初期化
    const f$ = useVuelidate(validator, props, {
      $autoDirty: true,
    });

    // 生年月日に関するチェック
    const validBirthDate = () => {
      f$.value.tfMhhkbtY.$reset();
      f$.value.tfMhhkbtY.$touch();
      if (props.tfMhhkbtY !== undefined && props.tfMhhkbtM !== undefined && props.tfMhhkbtD !== undefined) {
        const nowDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        const birthDate = new Date(props.tfMhhkbtY, props.tfMhhkbtM - 1, props.tfMhhkbtD);
        if (nowDate < birthDate) {
          f$.value.tfMhhkbtY.$errors.push(createErrorObject("生年月日に未来の日付が選択されています"));
        }
      }
    };

    return {
      props,
      enabledData,
      tfMhhkskSeiData,
      tfMhhkskMeiData,
      tfMhhksjSeiData,
      tfMhhksjMeiData,
      tfMhhkbtYData,
      tfMhhkbtMData,
      tfMhhkbtDData,
      tfMhhksxData,
      f$,
      validBirthDate,
      constants,
    };
  },
});
</script>

<style scoped>
.enable-check + label + div {
  animation: fadeout 0.5s;
  display: none;
}

.enable-check:checked + label + div {
  animation: fadein 0.5s;
  display: block;
}
</style>
