import { helpers, required } from "@vuelidate/validators";
import { computed } from "vue";

export type SCR020Form = {
  /** 確認番号  */
  authNumber: string;
};

export const SCR020FormValidateRule = computed(() => ({
  authNumber: {
    required: helpers.withMessage("確認番号を入力してください", required),
  },
}));
