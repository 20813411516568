
import { getPlanList, PlanRequest } from "@/api/plan";
import { useStore } from "@/store";
import { ConveniMutationTypes } from "@/store/modules/conveni";
import { EntryMutationTypes } from "@/store/modules/entry";
import { MailMutationTypes } from "@/store/modules/mail";
import { PlanMutationTypes } from "@/store/modules/plans";
import { StepMutationTypes } from "@/store/modules/steps";
import { getServerTime } from "common/src/api/serverTime";
import constants from "common/src/constants";
import { defineComponent } from "vue";
import { getConveniText } from "common/src/utils/codes";
import { AnotherMutationTypes } from "@/store/modules/another";
import { ErrorsMutationTypes } from "@/store/modules/errors";

export default defineComponent({
  setup() {
    const store = useStore();

    // メールアドレスを入力したかどうか
    const hasMailAddress = store.state.mail.mailAddress !== "";

    // コンビニ払いの場合のみ表示する情報を取得
    const isConveni = store.state.entry.msk01p.tfMshkhh === constants.HARAIKOMI_HOUHOU.CONVENI;
    const conveniCode = store.state.conveni.tfMsconv;
    const conveniConf = store.state.conveni.tfMscono;
    const conveniRecipt = store.state.conveni.tfMsreno;
    const conveniPayTerm = store.state.conveni.tfMspydt;
    const conveniReciptUrl = store.state.conveni.receiptUrl;

    // 画面初期表示タイミングでストアを初期化
    store.commit(EntryMutationTypes.RESET);
    store.commit(MailMutationTypes.RESET);
    store.commit(ConveniMutationTypes.RESET);
    store.commit(StepMutationTypes.RESET);
    store.commit(AnotherMutationTypes.RESET);
    store.commit(ErrorsMutationTypes.RESET_ERRORS);

    // 現在日・プランリストを取得してストアにセット
    getServerTime().then((res) => {
      store.commit(EntryMutationTypes.SET_ENTRY_DATE, res.data.serverDate);
      const planReq: PlanRequest = {
        entryDate: res.data.serverDate,
        productCode: constants.SHOUHIN_CODE.SANKO_LIFE_GUARD,
      };
      getPlanList(planReq).then((res) => store.commit(PlanMutationTypes.SET_PLAN_LIST, res.data.list));
    });

    return {
      hasMailAddress,
      isConveni,
      conveniCode,
      conveniConf,
      conveniRecipt,
      conveniPayTerm,
      conveniReciptUrl,
      constants,
      getConveniText,
    };
  },
});
