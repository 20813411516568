
import { computed, defineComponent, PropType } from "vue";
import { ErrorObject } from "@vuelidate/core";

/**
 * エラーフィールドコンポーネント
 * vuelidateのErrorObject配列・string配列・stringを受け取り、表示します
 */
export default defineComponent({
  props: {
    error: {
      type: [Array, String] as PropType<ErrorObject[] | string[] | string>,
      required: true,
    },
  },
  setup(props) {
    const errors = computed(() => {
      if (!props.error) {
        return [];
      }
      if (typeof props.error === "string") {
        return props.error !== "" ? [props.error] : [];
      } else if (props.error.length > 0 && (props.error[0] as ErrorObject).$uid) {
        return (props.error as ErrorObject[]).map((e) => e.$message);
      } else {
        return (props.error as string[]).map((e) => e);
      }
    });

    return {
      errors,
    };
  },
});
